import React, { useState } from 'react'
import styled from 'styled-components'
import Text from '../../components/Text/Text'
import { CopyIcon } from '../../components/Svg'

interface Props {
	toCopy: string
}

const StyleButton = styled(Text).attrs({ role: 'button' })`
	position: relative;
	display: flex;
	align-items: center;
	color: ${({ theme }) => theme.colors.primary};
`

const Tooltip = styled.div<{ isTooltipDisplayed: boolean }>`
	display: ${({ isTooltipDisplayed }) => (isTooltipDisplayed ? 'block' : 'none')};
	position: absolute;
	bottom: -22px;
	right: 0;
	left: 0;
	text-align: center;
	background-color: ${({ theme }) => theme.colors.contrast};
	color: ${({ theme }) => theme.colors.invertedContrast};
	border-radius: 16px;
	opacity: 0.7;
`

const CopyToClipboard: React.FC<Props> = ({ toCopy, children, ...props }) => {
	const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false)

	const copyToClipboardWithCommand = (content: string) => {
		const el = document.createElement('textarea')
		el.value = content
		document.body.appendChild(el)
		el.select()
		document.execCommand('copy')
		document.body.removeChild(el)
	}

	function displayTooltip() {
		setIsTooltipDisplayed(true)
		setTimeout(() => {
			setIsTooltipDisplayed(false)
		}, 1000)
	}

	return (
		<button
			className="fc-button -accent -small -tertiary _bdrd-max"
			type="button"
			onClick={() => {
				if (navigator.clipboard && navigator.permissions) {
					navigator.clipboard.writeText(toCopy).then(() => displayTooltip())
				} else if (document.queryCommandSupported('copy')) {
					copyToClipboardWithCommand(toCopy)
					displayTooltip()
				}
			}}
			{...props}
		>
			{children} <i className="far fa-copy _mgl-8px" />
			<Tooltip isTooltipDisplayed={isTooltipDisplayed}>Copied</Tooltip>
		</button>
	)
}

export default CopyToClipboard
