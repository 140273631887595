import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import {
	fetchFarmUserDataAsync,
	updateUserStakedBalance,
	updateUserBalance,
	updateUserPendingReward,
	fetchFarmUserDataAsyncV2,
} from 'state/actions'
import { unstake, sousUnstake, sousEmergencyUnstake, emergencyWithdraw } from 'utils/callHelpers'
import { useCafeteriaV2, useMasterchef, useSousChef } from './useContract'

export const useUnstake = (pid: number, isWithdrawFee100 = false) => {
	const dispatch = useAppDispatch()
	const { account } = useWeb3React()
	const masterChefContract = useMasterchef()

	const handleUnstake = useCallback(
		async (amount: string) => {
			const txHash = await unstake(masterChefContract, pid, amount, account, isWithdrawFee100)
			dispatch(fetchFarmUserDataAsync(account))
			console.info(txHash)
		},
		[account, dispatch, masterChefContract, pid],
	)

	return { onUnstake: handleUnstake }
}

export const useUnstakeV2 = (pid: number, isWithdrawFee100 = false) => {
	const dispatch = useAppDispatch()
	const { account } = useWeb3React()
	const cafeteriaV2Contract = useCafeteriaV2()

	const handleUnstake = useCallback(
		async (amount: string) => {
			const txHash = await unstake(cafeteriaV2Contract, pid, amount, account, isWithdrawFee100)
			dispatch(fetchFarmUserDataAsyncV2(account))
			console.info(txHash)
		},
		[account, dispatch, cafeteriaV2Contract, pid],
	)

	return { onUnstake: handleUnstake }
}

export const useEmergencyWithdraw = (pid: number) => {
	const dispatch = useAppDispatch()
	const { account } = useWeb3React()
	const masterChefContract = useMasterchef()

	const handleEmergencyWithdraw = useCallback(async () => {
		const txHash = await emergencyWithdraw(masterChefContract, pid, account)
		dispatch(fetchFarmUserDataAsync(account))
		console.info(txHash)
	}, [account, dispatch, masterChefContract, pid])

	return { onEmergencyWithdraw: handleEmergencyWithdraw }
}

export const useSousUnstake = (sousId, enableEmergencyWithdraw = false) => {
	const dispatch = useAppDispatch()
	const { account } = useWeb3React()
	const masterChefContract = useMasterchef()
	const sousChefContract = useSousChef(sousId)

	const handleUnstake = useCallback(
		async (amount: string, decimals: number) => {
			if (sousId === 0) {
				const txHash = await unstake(masterChefContract, 0, amount, account)
				console.info(txHash)
			} else if (enableEmergencyWithdraw) {
				const txHash = await sousEmergencyUnstake(sousChefContract, account)
				console.info(txHash)
			} else {
				const txHash = await sousUnstake(sousChefContract, amount, decimals, account)
				console.info(txHash)
			}
			dispatch(updateUserStakedBalance(sousId, account))
			dispatch(updateUserBalance(sousId, account))
			dispatch(updateUserPendingReward(sousId, account))
		},
		[account, dispatch, enableEmergencyWithdraw, masterChefContract, sousChefContract, sousId],
	)

	return { onUnstake: handleUnstake }
}
