import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
	{
		pid: 0,
		lpSymbol: 'COUPON',
		imageName: 'coupon-coupon',
		lpAddresses: {
			97: '0x2B0642FB991c78455403d77438d673EC7b17c52A',
			56: '0x084bb94e93891D74579B54Ab63ED24C4ef9cd5Ef',
		},
		token: tokens.coupon,
		quoteToken: tokens.coupon,
		isSinglePool: true,
	},
	{
		pid: 1,
		lpSymbol: 'COUPON-BNB LP',
		imageName: 'coupon-bnb',
		lpAddresses: {
			97: '0x5aa60f4d2cc1208f53e12bce2828f0de28f4a670',
			56: '0x9d368BA7176F77b2c0abe129eEB1a47B58CDde7E',
		},
		token: tokens.coupon,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 2,
		lpSymbol: 'COUPON-BUSD LP',
		imageName: 'coupon-busd',
		lpAddresses: {
			97: '0xD7d929a315A6BA8f3A12F07437108c32100E2847',
			56: '0x52560eBc7920617c9b7328186D1F4A136eAF07F4',
		},
		token: tokens.coupon,
		quoteToken: tokens.busd,
	},
	{
		pid: 3,
		lpSymbol: 'BNB-BUSD LP',
		imageName: 'bnb-busd',
		lpAddresses: {
			97: '0xD7d929a315A6BA8f3A12F07437108c32100E2847',
			56: '0xd241257d8fC4AC288fDEDA03dFF18aF373d968c8',
		},
		token: tokens.wbnb,
		quoteToken: tokens.busd,
	},
	{
		pid: 4,
		lpSymbol: 'kTUK',
		imageName: 'ktuk-coupon',
		lpAddresses: {
			97: '0xf37f180fBbA29681AcDcD7dca13F37c64eAB3272',
			56: '0xFAa6c12CDeB3A63085fD78644756566c69778c53',
		},
		token: tokens.ktuk,
		quoteToken: tokens.ktuk,
		isSinglePool: true,
	},
	{
		pid: 5,
		lpSymbol: 'LOREM',
		imageName: 'lorem-lorem',
		lpAddresses: {
			97: '',
			56: '0x401d9c80b8f349c462c1f85f16f0e7158216d98b',
		},
		token: tokens.lorem,
		quoteToken: tokens.lorem,
		isSinglePool: true,
	},
	{
		pid: 6,
		lpSymbol: 'wMMP',
		imageName: 'wmmp-coupon',
		lpAddresses: {
			97: '0xf37f180fBbA29681AcDcD7dca13F37c64eAB3272',
			56: '0x422d0a431d8fb752e3697e90ba04b3324ea0cb4a',
		},
		token: tokens.wmmp,
		quoteToken: tokens.wmmp,
		isSinglePool: true,
	},
	{
		pid: 7,
		lpSymbol: 'kKUB-BNB LP',
		imageName: 'kkub-bnb',
		lpAddresses: {
			97: '0xD7d929a315A6BA8f3A12F07437108c32100E2847',
			56: '0xCC32C1Ad924Ba80F96b526CABF088Ee7F7DD3799',
		},
		token: tokens.kkub,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 8,
		lpSymbol: 'kMATIC-BNB LP',
		imageName: 'kmatic-bnb',
		lpAddresses: {
			97: '0xD7d929a315A6BA8f3A12F07437108c32100E2847',
			56: '0xf51946D79BC438dDA6DC3E5B138C09c1949b38CC',
		},
		token: tokens.kmatic,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 9,
		lpSymbol: 'kMATIC-MATIC LP',
		imageName: 'kmatic-matic',
		lpAddresses: {
			97: '0xD7d929a315A6BA8f3A12F07437108c32100E2847',
			56: '0xd4ab4530F6d86605727591485812eBc258cd106d',
		},
		token: tokens.kmatic,
		quoteToken: tokens.matic,
	},
	{
		pid: 10,
		lpSymbol: 'kTUK-BNB LP',
		imageName: 'ktuk-bnb',
		lpAddresses: {
			97: '0xD7d929a315A6BA8f3A12F07437108c32100E2847',
			56: '0xae4d28a77676663528fb3590332744988bd67284',
		},
		token: tokens.ktuk,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 11,
		lpSymbol: 'USDT-BUSD LP',
		imageName: 'usdt-busd',
		lpAddresses: {
			97: '0xD7d929a315A6BA8f3A12F07437108c32100E2847',
			56: '0x2e3FDF8474F02D75933cA001BC5A70f6707DdB80',
		},
		token: tokens.usdt,
		quoteToken: tokens.busd,
	},
	{
		pid: 12,
		lpSymbol: 'kUSDC-BUSD LP',
		imageName: 'kusdc-busd',
		lpAddresses: {
			97: '',
			56: '0xbaccB690c22359E310eb12C72955a0572A597393',
		},
		token: tokens.kusdc,
		quoteToken: tokens.busd,
	},
	{
		pid: 13,
		lpSymbol: 'USDC-BUSD LP',
		imageName: 'usdc-busd',
		lpAddresses: {
			97: '',
			56: '0x563b905c8f398ac117c8acb5b747d6cb7b14903f',
		},
		token: tokens.usdc,
		quoteToken: tokens.busd,
	},
	{
		pid: 14,
		lpSymbol: 'UST-BUSD LP',
		imageName: 'ust-busd',
		lpAddresses: {
			97: '',
			56: '0x7f6b01c51fa055ac87276a3fb0d85c197b47ba23',
		},
		token: tokens.ust,
		quoteToken: tokens.busd,
	},
	{
		pid: 15,
		lpSymbol: 'VAI-BUSD LP',
		imageName: 'vai-busd',
		lpAddresses: {
			97: '',
			56: '0xfb215c506d8f547058e026bbd4f5715639799aa3',
		},
		token: tokens.vai,
		quoteToken: tokens.busd,
	},
	{
		pid: 16,
		lpSymbol: 'DAI-BUSD LP',
		imageName: 'dai-busd',
		lpAddresses: {
			97: '',
			56: '0x7f4dfdd554cbb709685009f575d489ef5bb24a20',
		},
		token: tokens.dai,
		quoteToken: tokens.busd,
	},
	{
		pid: 17,
		lpSymbol: 'BTCB-BNB LP',
		imageName: 'btcb-bnb',
		lpAddresses: {
			97: '',
			56: '0x7e837c13b54251dee0199cc2f5ac16b2b2c7a37d',
		},
		token: tokens.btcb,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 18,
		lpSymbol: 'KETH-ETH LP',
		imageName: 'keth-eth',
		lpAddresses: {
			97: '',
			56: '0xce6befb6102a3cd94815c7145303703839f47843',
		},
		token: tokens.keth,
		quoteToken: tokens.eth,
	},
	{
		pid: 19,
		lpSymbol: 'KETH-BNB LP',
		imageName: 'keth-bnb',
		lpAddresses: {
			97: '',
			56: '0x1a7b2c2cdb81f7053bd43e06a2a362be4bc14105',
		},
		token: tokens.keth,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 20,
		lpSymbol: 'KKCS-BNB LP',
		imageName: 'kkcs-bnb',
		lpAddresses: {
			97: '',
			56: '0x90114a70b0f1ffc84c8a9e60e8033f66834900b2',
		},
		token: tokens.kkcs,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 21,
		lpSymbol: 'LOREM-BNB LP',
		imageName: 'lorem-bnb',
		lpAddresses: {
			97: '',
			56: '0x4ac9c352954b2bcbf0affa34363cbdc1ea2ac242',
		},
		token: tokens.lorem,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 22,
		lpSymbol: 'WAFI-BNB LP',
		imageName: 'wafi-bnb',
		lpAddresses: {
			97: '',
			56: '0x43e8e6723a53bfe15455aab5f79f1249b242febb',
		},
		token: tokens.wafi,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 23,
		lpSymbol: 'WSAFEPAWS-BNB LP',
		imageName: 'wsafepaws-bnb',
		lpAddresses: {
			97: '',
			56: '0x57eb906f6732b5e42b777f9c73d9744867318a6a',
		},
		token: tokens.wsafepaws,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 24,
		lpSymbol: 'ALE-BNB LP',
		imageName: 'ale-bnb',
		lpAddresses: {
			97: '',
			56: '0x1d6cdcfe824ca2b196d700c5c01e5105152fcee2',
		},
		token: tokens.ale,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 25,
		lpSymbol: 'SZO-BNB LP',
		imageName: 'szo-bnb',
		lpAddresses: {
			97: '',
			56: '0x8b35c5f58009fcd6538f87d42fac540a7d4f7163',
		},
		token: tokens.szo,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 26,
		lpSymbol: 'FINIX-BNB LP',
		imageName: 'finix-bnb',
		lpAddresses: {
			97: '',
			56: '0x4d95fc88a32c8eab7f1b56c49f4668ff3b9f5583',
		},
		token: tokens.finix,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 27,
		lpSymbol: 'LEAF-BNB LP',
		imageName: 'leaf-bnb',
		lpAddresses: {
			97: '',
			56: '0x8ed24745ed0858d6be17d50c3dc58bd5cf3e4e8f',
		},
		token: tokens.leaf,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 28,
		lpSymbol: 'TUSD-BUSD LP',
		imageName: 'tusd-busd',
		lpAddresses: {
			97: '',
			56: '0xf2e3ae8366a34c310614e65abf210d41f1646cb2',
		},
		token: tokens.tusd,
		quoteToken: tokens.busd,
	},
	{
		pid: 29,
		lpSymbol: 'CEA-BNB LP',
		imageName: 'cea-bnb',
		lpAddresses: {
			97: '',
			56: '0xb59be39c8e8c10222f14cd6b69150c1241977d06',
		},
		token: tokens.cea,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 30,
		lpSymbol: 'KUSD-BUSD LP',
		imageName: 'kusd-busd',
		lpAddresses: {
			97: '',
			56: '0xe2601845f8978615f6f721aede0f8e23b292c0bc',
		},
		token: tokens.kusd,
		quoteToken: tokens.busd,
	},
	{
		pid: 31,
		lpSymbol: 'USDN-BUSD LP',
		imageName: 'usdn-busd',
		lpAddresses: {
			97: '',
			56: '0x15a546013ca850849fbc204a45038f35fcbb156d',
		},
		token: tokens.usdn,
		quoteToken: tokens.busd,
	},
	{
		pid: 32,
		lpSymbol: 'CAKE-BNB LP',
		imageName: 'cake-bnb',
		lpAddresses: {
			97: '',
			56: '0xf90e7a019b73b125e6937737097d52549900b460',
		},
		token: tokens.cake,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 33,
		lpSymbol: 'ttUSD-BUSD LP',
		imageName: 'ttusd-busd',
		lpAddresses: {
			97: '',
			56: '0x38f1d86facf4f4f971c80f7817c47b170c8a6c07',
		},
		token: tokens.ttusd,
		quoteToken: tokens.busd,
	},
	{
		pid: 34,
		lpSymbol: 'WUSD-BUSD LP',
		imageName: 'wusd-busd',
		lpAddresses: {
			97: '',
			56: '0xc91e1596908f825b485a85ec43e01bb14ff44b3b',
		},
		token: tokens.wusd,
		quoteToken: tokens.busd,
	},
	{
		pid: 35,
		lpSymbol: 'TENFI-BNB LP',
		imageName: 'tenfi-bnb',
		lpAddresses: {
			97: '',
			56: '0x87b874c8cd424ce13c65a95b4bd2ae488f166856',
		},
		token: tokens.tenfi,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 36,
		lpSymbol: 'GOONG-BNB LP',
		imageName: 'goong-bnb',
		lpAddresses: {
			97: '',
			56: '0x1Ddc47ca70b68CA6Bf7dEBd4209934e6364899A5',
		},
		token: tokens.goong,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 37,
		lpSymbol: 'BSW-BNB LP',
		imageName: 'bsw-bnb',
		lpAddresses: {
			97: '',
			56: '0xce0e9bded194b755aa7815faecfee851fc1f9fb0',
		},
		token: tokens.bsw,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 38,
		lpSymbol: 'BANANA-BNB LP',
		imageName: 'banana-bnb',
		lpAddresses: {
			97: '',
			56: '0x2b129AA5744c697c0A580296EC81c1Bd0A717E67',
		},
		token: tokens.banana,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 39,
		lpSymbol: 'DOPX-BNB LP',
		imageName: 'dopx-bnb',
		lpAddresses: {
			97: '',
			56: '0x21670ac7CCfEd702ff02763B20766DFbc869da32',
		},
		token: tokens.dopx,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 40,
		lpSymbol: 'LOOM-BNB LP',
		imageName: 'loom-bnb',
		lpAddresses: {
			97: '',
			56: '0x35ddf5ae5c0fcc69a5ac0536b386962de706a936',
		},
		token: tokens.loom,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 41,
		lpSymbol: 'wMMP-BNB LP',
		imageName: 'wmmp-bnb',
		lpAddresses: {
			97: '0xD7d929a315A6BA8f3A12F07437108c32100E2847',
			56: '0xA360Fa1De4C15ea54d61a923a285126cD5482723',
		},
		token: tokens.wmmp,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 42,
		lpSymbol: 'wMMP-BUSD LP',
		imageName: 'wmmp-busd',
		lpAddresses: {
			97: '0xD7d929a315A6BA8f3A12F07437108c32100E2847',
			56: '0xf71D708Fb2f342828e0a77C4AA8c6018709C18B0',
		},
		token: tokens.wmmp,
		quoteToken: tokens.busd,
	},
	{
		pid: 43,
		lpSymbol: 'wSAFEMOON-BNB LP',
		imageName: 'wsafemoon-bnb',
		lpAddresses: {
			97: '0xD7d929a315A6BA8f3A12F07437108c32100E2847',
			56: '0xcEb52cA20582a28255E7E66Fea42da3Cc285E1Ee',
		},
		token: tokens.wsafemoon,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 44,
		lpSymbol: 'wSAFEMARS-BNB LP',
		imageName: 'wsafemars-bnb',
		lpAddresses: {
			97: '0xD7d929a315A6BA8f3A12F07437108c32100E2847',
			56: '0x45eA9Dfad5e95bEE31eba9439aAc9D38c79f1ed2',
		},
		token: tokens.wsafemars,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 45,
		lpSymbol: 'SCZ-BNB LP',
		imageName: 'scz-bnb',
		lpAddresses: {
			97: '',
			56: '0xaD468bCBcB33037e061ed5eC905ddEA06cAFC67F',
		},
		token: tokens.scz,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 46,
		lpSymbol: 'UNI-BNB LP',
		imageName: 'uni-bnb',
		lpAddresses: {
			97: '',
			56: '0x949a53a16157e9fe8340237a4da9943d28a5f616',
		},
		token: tokens.uni,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 47,
		lpSymbol: 'SUSHI-BNB LP',
		imageName: 'sushi-bnb',
		lpAddresses: {
			97: '',
			56: '0x5a39F24FA18E89Ad1FD16D1c90228cc11BbCD636',
		},
		token: tokens.sushi,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 48,
		lpSymbol: 'ALPACA-BNB LP',
		imageName: 'alpaca-bnb',
		lpAddresses: {
			97: '',
			56: '0xab586E8664d1864F513edcE638a5754273Ac1e97',
		},
		token: tokens.alpaca,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 49,
		lpSymbol: 'WEX-BNB LP',
		imageName: 'wex-bnb',
		lpAddresses: {
			97: '',
			56: '0x18234010A241A016b5165B5569ea4A2561D5065e',
		},
		token: tokens.wex,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 50,
		lpSymbol: 'EGG-BNB LP',
		imageName: 'egg-bnb',
		lpAddresses: {
			97: '',
			56: '0xa11f9f0E972edf589534E8174b5de75E2eE826fF',
		},
		token: tokens.egg,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 51,
		lpSymbol: 'PANTHER-BNB LP',
		imageName: 'panther-bnb',
		lpAddresses: {
			97: '',
			56: '0xd59E5280171f0A3401fd94F7Ec9EabfFDB941578',
		},
		token: tokens.panther,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 52,
		lpSymbol: 'TUK-BNB LP',
		imageName: 'tuk-bnb',
		lpAddresses: {
			97: '',
			56: '0x3e35b9d761db8b8fca037d6eb84389b81ae8366f',
		},
		token: tokens.tuk,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 53,
		lpSymbol: 'KEBAB-BNB LP',
		imageName: 'kebab-bnb',
		lpAddresses: {
			97: '',
			56: '0xc9388efa1f24e2f16e8717841b1b15043384da43',
		},
		token: tokens.kebab,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 54,
		lpSymbol: 'PACOCA-BNB LP',
		imageName: 'pacoca-bnb',
		lpAddresses: {
			97: '',
			56: '0x09534834a2abec732f56327afd9d51b2600e3640',
		},
		token: tokens.pacoca,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 55,
		lpSymbol: 'ZMN-BNB LP',
		imageName: 'zmn-bnb',
		lpAddresses: {
			97: '',
			56: '0x4777e71dD165ea929bB37d0B52d8C30522f2BC0e',
		},
		token: tokens.zmn,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 56,
		lpSymbol: 'ZFARM-BNB LP',
		imageName: 'zfarm-bnb',
		lpAddresses: {
			97: '',
			56: '0x2c64149076CCaE68D8C6DAD3F475Eeb4aD3e9AAB',
		},
		token: tokens.zfarm,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 57,
		lpSymbol: 'RIGEL-BNB LP',
		imageName: 'rigel-bnb',
		lpAddresses: {
			97: '',
			56: '0x3E96d9121844cE36824BdC1578188F2DB8fea883',
		},
		token: tokens.rgp,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 58,
		lpSymbol: 'WAD-BNB LP',
		imageName: 'wad-bnb',
		lpAddresses: {
			97: '',
			56: '0x6f274a9212a4DF9A2e6dA8CA507973245BF8a30b',
		},
		token: tokens.wad,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 59,
		lpSymbol: 'DINOP-BNB LP',
		imageName: 'dinop-bnb',
		lpAddresses: {
			97: '',
			56: '0xdE455103143cB7cB650127cf4e3fAAf72b097151',
		},
		token: tokens.dinop,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 60,
		lpSymbol: 'JDI-BNB LP',
		imageName: 'jdi-bnb',
		lpAddresses: {
			97: '',
			56: '0x47e66d20122200841eE7afACE1e1CE9e68Bf49b6',
		},
		token: tokens.jdi,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 61,
		lpSymbol: 'INFTEE-BNB LP',
		imageName: 'inftee-bnb',
		lpAddresses: {
			97: '',
			56: '0xb1738027D709838A1fa46E4a4aFFD0fc971a8D89',
		},
		token: tokens.inftee,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 62,
		lpSymbol: 'RBH-BNB LP',
		imageName: 'rbh-bnb',
		lpAddresses: {
			97: '',
			56: '0xa8ce91e638C62AC8045D54c3cc95f0D50D186493',
		},
		token: tokens.rbh,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 63,
		lpSymbol: 'SIX-BNB LP',
		imageName: 'six-bnb',
		lpAddresses: {
			97: '',
			56: '0x1FB3eba2FFe158eF883682d4ec1DEdBBE6cf6342',
		},
		token: tokens.six,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 64,
		lpSymbol: 'SMOY-BNB LP',
		imageName: 'smoy-bnb',
		lpAddresses: {
			97: '',
			56: '0xf8c3cedc71380BCB0b7103a270060c0041098bA2',
		},
		token: tokens.smoy,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 65,
		lpSymbol: 'SMOY',
		imageName: 'smoy-smoy',
		lpAddresses: {
			97: '',
			56: '0xBdb44DF0A914c290DFD84c1eaf5899d285717fdc',
		},
		token: tokens.smoy,
		quoteToken: tokens.smoy,
		isSinglePool: true,
	},
	{
		pid: 66,
		lpSymbol: 'kREI-BNB LP',
		imageName: 'krei-bnb',
		lpAddresses: {
			97: '',
			56: '0x5471907CC35E58C818BAE48FEC4c807073855241',
		},
		token: tokens.krei,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 67,
		lpSymbol: 'STU-BNB LP',
		imageName: 'stu-bnb',
		lpAddresses: {
			97: '',
			56: '0x9dC562C0Ef8dC817bA0812A8629639cfff2c6BCD',
		},
		token: tokens.stu,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 68,
		lpSymbol: 'STU',
		imageName: 'stu-stu',
		lpAddresses: {
			97: '',
			56: '0x5815BcCb3D13A071AFF388e5981b1828a6045854',
		},
		token: tokens.stu,
		quoteToken: tokens.stu,
		isSinglePool: true,
	},
	{
		pid: 69,
		lpSymbol: 'STU-BUSD LP',
		imageName: 'stu-busd',
		lpAddresses: {
			97: '',
			56: '0xa4b1cf4c484ce8a504695bc49c2872eb0e83d253',
		},
		token: tokens.stu,
		quoteToken: tokens.busd,
	},
	{
		pid: 70,
		lpSymbol: 'kREI-BUSD LP',
		imageName: 'krei-busd',
		lpAddresses: {
			97: '',
			56: '0xa987e41243b2D02F7D30259457f0fbd27De0aF8d',
		},
		token: tokens.krei,
		quoteToken: tokens.busd,
	},
	{
		pid: 71,
		lpSymbol: 'KSW-BNB LP',
		imageName: 'ksw-bnb',
		lpAddresses: {
			97: '',
			56: '0x2be28Dbf88DaFD390b4A07f5DD1FC80244AA84C6',
		},
		token: tokens.ksw,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 72,
		lpSymbol: 'KSW-BUSD LP',
		imageName: 'ksw-busd',
		lpAddresses: {
			97: '',
			56: '0xe4fcAda073F962AA514391cEFfEED38d1C959Cc9',
		},
		token: tokens.ksw,
		quoteToken: tokens.busd,
	},
	{
		pid: 73,
		lpSymbol: 'RYC-BNB LP',
		imageName: 'ryc-bnb',
		lpAddresses: {
			97: '',
			56: '0x12B90cA01b150179Da3405845bCcAa5dfc3d5f95',
		},
		token: tokens.ryc,
		quoteToken: tokens.wbnb,
	},
]

export default farms
