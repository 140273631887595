const tokens = {
	bnb: {
		symbol: 'BNB',
		projectLink: 'https://www.binance.com/',
	},
	coupon: {
		symbol: 'COUPON',
		address: {
			56: '0x084bb94e93891D74579B54Ab63ED24C4ef9cd5Ef',
			97: '0x2B0642FB991c78455403d77438d673EC7b17c52A',
		},
		decimals: 18,
		projectLink: 'https://www.foodcourt.finance/',
	},
	lorem: {
		symbol: 'LOREM',
		address: {
			56: '0x401d9c80b8f349c462c1f85f16f0e7158216d98b',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://loremboard.finance/',
	},
	ust: {
		symbol: 'UST',
		address: {
			56: '0x23396cf899ca06c4472205fc903bdb4de249d6fc',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://mirror.finance/',
	},
	vai: {
		symbol: 'VAI',
		address: {
			56: '0x4BD17003473389A42DAF6a0a729f6Fdb328BbBd7',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://venus.io/',
	},
	tusd: {
		symbol: 'TUSD',
		address: {
			56: '0x14016e85a25aeb13065688cafb43044c2ef86784',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://www.trusttoken.com/',
	},
	kusd: {
		symbol: 'KUSD',
		address: {
			56: '0x940ff63e82d15fb47371bfe5a4ed7d7d183de1a5',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://dopple.finance/',
	},
	usdn: {
		symbol: 'USDN',
		address: {
			56: '0x03ab98f5dc94996f8c33e15cd4468794d12d41f9',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://neutrino.at/',
	},
	ttusd: {
		symbol: 'ttUSD',
		address: {
			56: '0x13a5bcff72b024619df4d62093941c382b6d6348',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://space.tuktuk.finance/#/',
	},
	wusd: {
		symbol: 'WUSD',
		address: {
			56: '0x3fF997eAeA488A082fb7Efc8e6B9951990D0c3aB',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://wault.finance/',
	},
	btcb: {
		symbol: 'BTCB',
		address: {
			56: '0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://www.binance.com/',
	},
	cea: {
		symbol: 'CEA',
		address: {
			56: '0x402bf56d4500f7bc86771390925ff8d059d29842',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://www.binance.com/',
	},
	keth: {
		symbol: 'KETH',
		address: {
			56: '0x58471A764a76AA8c3Ed17517AEFc97F6bFA6e2D0',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://www.northbridge.link/',
	},
	eth: {
		symbol: 'eth',
		address: {
			56: '0x2170ed0880ac9a755fd29b2688956bd959f933f8',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://www.binance.com/',
	},
	kkcs: {
		symbol: 'kkcs',
		address: {
			56: '0x4D96b1C8d60702425426C7190F9C2A1A54d2E38E',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://kucoin.com/',
	},
	wafi: {
		symbol: 'wafi',
		address: {
			56: '0x7039Eb99215B345588B94BC8830E186C457a8fB3',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://farm.aquafinance.live/',
	},
	wsafepaws: {
		symbol: 'wsafepaws',
		address: {
			56: '0x0Be20D632Eec8290de659D1712181bb616bfE556',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://safepaws.finance/',
	},
	ale: {
		symbol: 'ale',
		address: {
			56: '0x99ca242f20424a6565cc17a409e557fa95e25bd7',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://app.aleswap.finance/#/home',
	},
	szo: {
		symbol: 'szo',
		address: {
			56: '0xab837ac8800083653e5d15ceaa7e23d20adfc991',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://shuttleone.network/2020/',
	},
	finix: {
		symbol: 'finix',
		address: {
			56: '0x0f02b1f5af54e04fb6dd6550f009ac2429c4e30d',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://bsc.definix.com/',
	},
	leaf: {
		symbol: 'leaf',
		address: {
			56: '0x1cbddf83de068464eba3a4e319bd3197a7eea12c',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://seeder.finance/',
	},
	cake: {
		symbol: 'CAKE',
		address: {
			56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://pancakeswap.finance/',
	},
	tenfi: {
		symbol: 'TENFI',
		address: {
			56: '0xd15c444f1199ae72795eba15e8c1db44e47abf62',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://ten.finance/',
	},
	bsw: {
		symbol: 'BSW',
		address: {
			56: '0x965f527d9159dce6288a2219db51fc6eef120dd1',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://biswap.org/',
	},
	banana: {
		symbol: 'BANANA',
		address: {
			56: '0x603c7f932ed1fc6575303d8fb018fdcbb0f39a95',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://apeswap.finance/',
	},
	dopx: {
		symbol: 'DOPX',
		address: {
			56: '0x802a183ac9f6b082716dfee55432ed0c04acb49a',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://dopple.finance/',
	},
	loom: {
		symbol: 'LOOM',
		address: {
			56: '0xe6ce27025f13f5213bbc560dc275e292965a392f',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://loomx.io/',
	},
	uni: {
		symbol: 'UNI',
		address: {
			56: '0xbf5140a22578168fd562dccf235e5d43a02ce9b1',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://uniswap.org/',
	},
	smoy: {
		symbol: 'SMOY',
		address: {
			56: '0xBdb44DF0A914c290DFD84c1eaf5899d285717fdc',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://samoyedfinance.app/',
	},
	sushi: {
		symbol: 'SUSHI',
		address: {
			56: '0x947950bcc74888a40ffa2593c5798f11fc9124c4',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://sushi.com/',
	},
	alpaca: {
		symbol: 'ALPACA',
		address: {
			56: '0x8f0528ce5ef7b51152a59745befdd91d97091d2f',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://www.alpacafinance.org/',
	},
	wex: {
		symbol: 'WEX',
		address: {
			56: '0xa9c41a46a6b3531d28d5c32f6633dd2ff05dfb90',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://wault.finance/',
	},
	egg: {
		symbol: 'EGG',
		address: {
			56: '0xf952fc3ca7325cc27d15885d37117676d25bfda6',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://www.goosedefi.com/',
	},
	panther: {
		symbol: 'PANTHER',
		address: {
			56: '0x1f546ad641b56b86fd9dceac473d1c7a357276b7',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://pantherswap.com/',
	},
	tuk: {
		symbol: 'TUK',
		address: {
			56: '0xe363096e780fb8675966b3ffca88bedd62b838fa',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://space.tuktuk.finance/#/',
	},
	kebab: {
		symbol: 'KEBAB',
		address: {
			56: '0xc9388efa1f24e2f16e8717841b1b15043384da43',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://kebabfinance.com/',
	},
	pacoca: {
		symbol: 'PACOCA',
		address: {
			56: '0x55671114d774ee99d653d6c12460c780a67f1d18',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://pacoca.io/',
	},

	scz: {
		symbol: 'SCZ',
		address: {
			56: '0x39f1014a88c8ec087ceDF1BFc7064d24f507b894',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://safebsc.finance/',
	},
	goong: {
		symbol: 'GOONG',
		address: {
			56: '0x2afAB709fEAC97e2263BEd78d94aC2951705dB50',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://tomyumgoong.finance/',
	},
	kusdc: {
		symbol: 'kUSDC',
		address: {
			56: '0xa72A60a908E6CFD89FeEC670E332422E3f3655ae',
			97: '',
		},
		decimals: 6,
		projectLink: 'https://www.foodcourt.finance/',
	},
	kkub: {
		symbol: 'kKUB',
		address: {
			56: '0x65270ad4d7d33D797EA8d2D4Ab7f3427E4C848B1',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://www.foodcourt.finance/',
	},
	ktuk: {
		symbol: 'kTUK',
		address: {
			56: '0xFAa6c12CDeB3A63085fD78644756566c69778c53',
			97: '0xFAa6c12CDeB3A63085fD78644756566c69778c53',
		},
		decimals: 18,
		projectLink: 'https://www.foodcourt.finance/',
	},
	kmatic: {
		symbol: 'kMatic',
		address: {
			56: '0x032574B64Bf6fa42951f836CC8c5099d1C5747d3',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://www.foodcourt.finance/',
	},
	zmn: {
		symbol: 'zmn',
		address: {
			56: '0xFCb8a4B1a0B645e08064e05B98E9cc6f48D2aa57',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://zmine.com/',
	},
	zfarm: {
		symbol: 'zfarm',
		address: {
			56: '0x42d1B21EaBE04D308148eA9Ab90Be674b64b4eef',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://zfarm.finance/',
	},
	rgp: {
		symbol: 'rgp',
		address: {
			56: '0xFA262F303Aa244f9CC66f312F0755d89C3793192',
			97: '',
		},
		decimals: 18,
		projectLink: 'http://www.rigelprotocol.com/',
	},
	wad: {
		symbol: 'wad',
		address: {
			56: '0x0fEAdcC3824E7F3c12f40E324a60c23cA51627fc',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://www.wardenswap.com/',
	},
	krei: {
		symbol: 'krei',
		address: {
			56: '0x5493c36a1778051e2a79ce6C2E1a471f9812Cd44',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://www.killswitch.finance/',
	},
	stu: {
		symbol: 'stu',
		address: {
			56: '0x5815BcCb3D13A071AFF388e5981b1828a6045854',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://stakemars.com/',
	},
	ksw: {
		symbol: 'ksw',
		address: {
			56: '0x270178366a592bA598C2e9d2971DA65f7bAa7C86',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://app.killswitch.finance/',
	},
	dinop: {
		symbol: 'dinop',
		address: {
			56: '0xea90DC6F64d18771Ca1dac8098526a9082265B42',
			97: '',
		},
		decimals: 9,
		projectLink: 'https://www.dinopark.fun/',
	},
	jdi: {
		symbol: 'jdi',
		address: {
			56: '0x0491648C910ad2c1aFaab733faF71D30313Df7FC',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://www.jdiyield.com/',
	},
	inftee: {
		symbol: 'inftee',
		address: {
			56: '0xc350CaA89Eb963D5D6b964324A0a7736D8d65533',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://infinitee.finance/',
	},
	rbh: {
		symbol: 'rbh',
		address: {
			56: '0xD5779F2F9D7D239228e4e78bC78F50768661A081',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://robinhoodswap.finance/',
	},
	six: {
		symbol: 'six',
		address: {
			56: '0x070a9867Ea49CE7AFc4505817204860e823489fE',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://www.six.network/',
	},
	wsafemoon: {
		symbol: 'wSAFEMOON',
		address: {
			56: '0xa3863434a1Fc699185b3E6809a933056D1178366',
			97: '',
		},
		decimals: 9,
		projectLink: 'https://www.foodcourt.finance/',
	},
	wsafemars: {
		symbol: 'wSAFEMARS',
		address: {
			56: '0x40733aBc9Acb7d48Caa632ee83E4e7B3d0008d9D',
			97: '',
		},
		decimals: 9,
		projectLink: 'https://www.foodcourt.finance/',
	},
	rsafemoon: {
		symbol: 'rSAFEMOON',
		address: {
			56: '0xeA760c8de4ce92e34cc3BD8b4ad9a85C13a91e6C',
			97: '',
		},
		decimals: 9,
		projectLink: 'https://www.foodcourt.finance/',
	},
	rsafemars: {
		symbol: 'rSAFEMARS',
		address: {
			56: '0xA651fdAa684B704C8e64eBbb71413245C01f52a1',
			97: '',
		},
		decimals: 9,
		projectLink: 'https://www.foodcourt.finance/',
	},
	matic: {
		symbol: 'MATIC',
		address: {
			56: '0xcc42724c6683b7e57334c4e856f4c9965ed682bd',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://www.foodcourt.finance/',
	},


	// cake: {
	// 	symbol: 'CAKE',
	// 	address: {
	// 		56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
	// 		97: '0xa35062141Fa33BCA92Ce69FeD37D0E8908868AAe',
	// 	},
	// 	decimals: 18,
	// 	projectLink: 'https://pancakeswap.finance/',
	// },
	wbnb: {
		symbol: 'wBNB',
		address: {
			56: '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c',
			97: '0x094616F0BdFB0b526bD735Bf66Eca0Ad254ca81F',
		},
		decimals: 18,
		projectLink: 'https://pancakeswap.finance/',
	},
	busd: {
		symbol: 'BUSD',
		address: {
			56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
			97: '0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee',
		},
		decimals: 18,
		projectLink: 'https://www.paxos.com/busd/',
	},
	rmmp: {
		symbol: 'rMMP',
		address: {
			56: '0x3e45fd22cc219f640b3a608e274f5e0a5a6256a5',
			97: '0xf37f180fBbA29681AcDcD7dca13F37c64eAB3272',
		},
		decimals: 18,
		projectLink: 'https://moonmakerprotocol.com/',
	},
	wmmp: {
		symbol: 'wMMP',
		address: {
			56: '0x422d0a431d8fb752e3697e90ba04b3324ea0cb4a',
			97: '0xB1F5E032bE42097553DCEA281707BA3f9B6553ce',
		},
		decimals: 18,
		projectLink: 'https://moonmakerprotocol.com/',
	},
	usdc: {
		symbol: 'USDC',
		address: {
			56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://www.centre.io/usdc',
	},
	dai: {
		symbol: 'DAI',
		address: {
			56: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
			97: '',
		},
		decimals: 18,
		projectLink: 'http://www.makerdao.com/',
	},
	usdt: {
		symbol: 'USDT',
		address: {
			97: '0xE02dF9e3e622DeBdD69fb838bB799E3F168902c5',
			56: '0x55d398326f99059ff775485246999027b3197955',
		},
		decimals: 18,
		projectLink: 'https://tether.to/',
	},
	hzn: {
		symbol: 'HZN',
		address: {
			56: '0xc0eff7749b125444953ef89682201fb8c6a917cd',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://horizonprotocol.com/',
	},
	ryc: {
		symbol: 'RYC',
		address: {
			56: '0xcbF7aeb44BA6603e1132c713316a648eDDd52509',
			97: '',
		},
		decimals: 18,
		projectLink: 'https://ryoii.io/',
	},
}

export default tokens
