import { MenuEntry } from '../../uikit-foodcourt'

const config: MenuEntry[] = [
	{
		label: 'Home',
		icon: 'HomeIcon',
		href: '/',
	},
	...(process.env.REACT_APP_ENABLE_DUAL_LAUNCH_UI === 'true'
		? [
				{
					label: 'Dual-Lunch',
					icon: '',
					href: 'https://lunch.foodcourt.finance/',
					subtitle: 'Get COUPON',
				},
		  ]
		: []),
	{
		label: 'Exchange',
		icon: 'TradeIcon',
		href: 'https://exchange.foodcourt.finance/#swap',
	},
	{
		label: 'Liquidity',
		icon: 'TradeIcon',
		href: 'https://exchange.foodcourt.finance/#pool',
	},
	{
		label: 'Wrap',
		icon: 'TradeIcon',
		href: 'https://exchange.foodcourt.finance/#wrap',
	},
	{
		label: 'Cafeteria Fl.2',
		icon: 'FarmIcon',
		href: '/farmsV2',
		subtitle: 'Farm',
	},
	{
		label: 'SnackBar Fl.2',
		icon: 'PoolIcon',
		href: '/poolsV2',
		subtitle: 'Pool',
	},
	{
		label: 'Bridge',
		icon: '',
		href: 'https://www.northbridge.link',
		subtitle: 'Northbridge',
	},
	{
		label: 'ILO',
		icon: '',
		href: 'https://biolab.finance',
		subtitle: 'Biolab',
	},
	{
		label: 'Fleamarket',
		icon: '',
		href: 'https://fleamarket.foodcourt.finance',
		subtitle: 'Vote my favourite food',
	},
	{
		label: 'Supermarket',
		icon: '',
		href: 'https://supermarket.foodcourt.finance',
		subtitle: 'View my wallet',
	},
	{
		label: 'Festival 🎉',
		icon: '',
		href: 'https://iff.foodcourt.finance',
		subtitle: 'International Food',
	},
	{
		label: 'LuckyLion 🦁',
		icon: 'NftIcon',
		href: 'https://app.luckylion.io/games',
		subtitle: 'Games',
	},
	{
		label: 'Closed Farms',
		icon: 'FarmIcon',
		href: '/farms',
		subtitle: 'Farm',
	},
	{
		label: 'Closed Pools',
		icon: 'PoolIcon',
		href: '/pools',
		subtitle: 'Pool',
	},
	{
		label: 'Portfolio',
		icon: 'PredictionsIcon',
		href: '/portfolio',
		subtitle: 'My portfolio',
	},
]

export default config
