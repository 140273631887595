import React from 'react'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import { Text, Flex, LinkExternal } from '../../../../uikit-foodcourt'

export interface ExpandableSectionProps {
	single?: boolean
	bscScanAddress?: string
	infoAddress?: string
	removed?: boolean
	totalValueFormatted?: string
	lpLabel?: string
	addLiquidityUrl?: string
	myValueFormatted?: string
}

const Wrapper = styled.div`
	margin-top: 24px;
`

const StyledLinkExternal = styled(LinkExternal)`
	font-weight: 400;
`

const DetailsSection: React.FC<ExpandableSectionProps> = ({
	single,
	bscScanAddress,
	infoAddress,
	removed,
	totalValueFormatted,
	lpLabel,
	addLiquidityUrl,
	myValueFormatted,
}) => {
	const { t } = useTranslation()

	return (
		<Wrapper>
			{!single && (
				<Flex justifyContent="space-between">
					<Text>Value:</Text>
					<Text>{myValueFormatted}</Text>
				</Flex>
			)}
			<Flex justifyContent="space-between">
				<Text>{t('Total Liquidity')}:</Text>
				<Text>{totalValueFormatted}</Text>
			</Flex>
			{!removed && (
				<StyledLinkExternal href={addLiquidityUrl}>
					{t(`Get ${lpLabel}`, { name: lpLabel })}
				</StyledLinkExternal>
			)}
			<StyledLinkExternal href={bscScanAddress}>{t('View Contract')}</StyledLinkExternal>
		</Wrapper>
	)
}

export default DetailsSection
