import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { provider as ProviderType } from 'web3-core'
import BigNumber from 'bignumber.js'
import { useLocation } from 'react-router-dom'
import { getAddress } from 'utils/addressHelpers'
import { getBep20Contract } from 'utils/contractHelpers'
import { Button, Flex, Text } from '../../../../uikit-foodcourt'
import { Farm } from 'state/types'
import { useTranslation } from 'contexts/Localization'
import useWeb3 from 'hooks/useWeb3'
import { useApprove } from 'hooks/useApprove'
import UnlockButton from 'components/UnlockButton'
import StakeAction from './StakeAction'
import HarvestAction from './HarvestAction'

const Action = styled.div`
	padding-top: 16px;
`
export interface FarmWithStakedValue extends Farm {
	apr?: number
}

interface FarmCardActionsProps {
	farm: FarmWithStakedValue
	provider?: ProviderType
	account?: string
	addLiquidityUrl?: string
}

const CardActions: React.FC<FarmCardActionsProps> = ({ farm, account, addLiquidityUrl }) => {
	const { t } = useTranslation()
	const [requestedApproval, setRequestedApproval] = useState(false)
	const { pid, lpAddresses } = farm
	const {
		allowance: allowanceAsString = 0,
		tokenBalance: tokenBalanceAsString = 0,
		stakedBalance: stakedBalanceAsString = 0,
		earnings: earningsAsString = 0,
	} = farm.userData || {}
	const allowance = new BigNumber(allowanceAsString)
	const tokenBalance = new BigNumber(tokenBalanceAsString)
	const stakedBalance = new BigNumber(stakedBalanceAsString)
	const earnings = new BigNumber(earningsAsString)
	const lpAddress = getAddress(lpAddresses)
	const lpName = farm.lpSymbol.toUpperCase()
	const isApproved = account && allowance && allowance.isGreaterThan(0)
	const web3 = useWeb3()
	const location = useLocation()

	const lpContract = getBep20Contract(lpAddress, web3)

	const { onApprove } = useApprove(lpContract)

	const handleApprove = useCallback(async () => {
		try {
			setRequestedApproval(true)
			await onApprove()
			setRequestedApproval(false)
		} catch (e) {
			console.error(e)
		}
	}, [onApprove])

	// console.log(farm)

	const renderApprovalOrStakeButton = () => {
		if (process.env.REACT_APP_ENABLE_FARM === 'false') {
			return (
				<Button mt="8px" width="100%" disabled>
					Coming soon
				</Button>
			)
		}

		return isApproved ? (
			<StakeAction
				stakedBalance={stakedBalance}
				tokenBalance={tokenBalance}
				tokenName={lpName}
				pid={pid}
				addLiquidityUrl={addLiquidityUrl}
				decimals={farm.isSinglePool ? farm.token.decimals : 18}
				isWithdrawFee100={farm.isWithdrawFee100}
			/>
		) : (
			<Button
				mt="8px"
				width="100%"
				disabled={requestedApproval || location.pathname.includes('archived')}
				onClick={handleApprove}
			>
				{t('Approve Contract')}
			</Button>
		)
	}

	return (
		<Action>
			<Flex>
				<Text bold textTransform="uppercase" color="secondary" fontSize="12px" pr="3px">
					{/* TODO: Is there a way to get a dynamic value here from useFarmFromSymbol? */}
					COUPON
				</Text>
				<Text bold textTransform="uppercase" color="textSubtle" fontSize="12px">
					{t('Earned')}
				</Text>
			</Flex>
			<HarvestAction earnings={earnings} pid={pid} isWithdrawFee100={farm.isWithdrawFee100} token={farm.token}/>
			<Flex>
				<Text bold textTransform="uppercase" color="secondary" fontSize="12px" pr="3px">
					{lpName}
				</Text>
				<Text bold textTransform="uppercase" color="textSubtle" fontSize="12px">
					{t('Staked')}
				</Text>
			</Flex>
			{!account ? <UnlockButton mt="8px" width="100%" /> : renderApprovalOrStakeButton()}
		</Action>
	)
}

export default CardActions
