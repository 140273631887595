import React, { useState } from 'react'
import BigNumber from 'bignumber.js'
import styled, { keyframes } from 'styled-components'
import { Flex, Text, Skeleton } from '../../../../uikit-foodcourt'
import { Farm } from 'state/types'
import { provider as ProviderType } from 'web3-core'
import { useTranslation } from 'contexts/Localization'
import ExpandableSectionButton from 'components/ExpandableSectionButton'
import { BASE_ADD_LIQUIDITY_URL } from 'config'
import getLiquidityUrlPathParts from 'utils/getLiquidityUrlPathParts'
import DetailsSection from './DetailsSection'
import CardHeading from './CardHeading'
import CardActionsContainer from './CardActionsContainer'
import ApyButton from './ApyButton'
import { useFarmUserV2 } from 'state/hooks'
import { getFullDisplayBalance } from 'utils/formatBalance'

export interface FarmWithStakedValue extends Farm {
	apr?: number
	liquidity?: BigNumber
	lpValueInUsd?: BigNumber
}

const RainbowLight = keyframes`
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
`

const StyledCardAccent = styled.div`
	background: linear-gradient(
		45deg,
		rgba(255, 0, 0, 1) 0%,
		rgba(255, 154, 0, 1) 10%,
		rgba(208, 222, 33, 1) 20%,
		rgba(79, 220, 74, 1) 30%,
		rgba(63, 218, 216, 1) 40%,
		rgba(47, 201, 226, 1) 50%,
		rgba(28, 127, 238, 1) 60%,
		rgba(95, 21, 242, 1) 70%,
		rgba(186, 12, 248, 1) 80%,
		rgba(251, 7, 217, 1) 90%,
		rgba(255, 0, 0, 1) 100%
	);
	background-size: 300% 300%;
	animation: ${RainbowLight} 2s linear infinite;
	border-radius: 32px;
	filter: blur(6px);
	position: absolute;
	top: -2px;
	right: -2px;
	bottom: -2px;
	left: -2px;
	z-index: -1;
`

const FCard = styled.div`
	align-self: baseline;
	background: ${(props) => props.theme.card.background};
	border-radius: 32px;
	box-shadow: 0px 2px 12px -8px rgba(25, 19, 38, 0.1), 0px 1px 1px rgba(25, 19, 38, 0.05);
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: 24px;
	position: relative;
	text-align: center;
`

const Divider = styled.div`
	background-color: ${({ theme }) => theme.colors.borderColor};
	height: 1px;
	margin: 28px auto;
	width: 100%;
`

const ExpandingWrapper = styled.div<{ expanded: boolean }>`
	height: ${(props) => (props.expanded ? '100%' : '0px')};
	overflow: hidden;
`

interface FarmCardProps {
	farm: FarmWithStakedValue
	removed: boolean
	couponPrice?: BigNumber
	provider?: ProviderType
	account?: string
	single?: boolean
	withdraw100fee?: boolean
}

const FarmCard: React.FC<FarmCardProps> = ({
	farm,
	removed,
	couponPrice,
	account,
	single = false,
	withdraw100fee = false,
}) => {
	const { t } = useTranslation()
	const { stakedBalance } = useFarmUserV2(farm.pid)

	const [showExpandableSection, setShowExpandableSection] = useState(false)

	// We assume the token name is coin pair + lp e.g. CAKE-BNB LP, LINK-BNB LP,
	// NAR-CAKE LP. The images should be cake-bnb.svg, link-bnb.svg, nar-cake.svg
	const farmImage = farm.imageName

	const totalValueFormatted = farm.liquidity
		? `$${farm.liquidity.toNumber().toLocaleString(undefined, { maximumFractionDigits: 0 })}`
		: '-'

	const myValueFormatted = farm.lpValueInUsd
		? `$${new BigNumber(getFullDisplayBalance(farm.lpValueInUsd.times(stakedBalance), 18, 2)).toNumber().toLocaleString(undefined, { maximumFractionDigits: 0 })}`
		: '-'

	const lpLabel = farm.lpSymbol && farm.lpSymbol.toUpperCase().replace('PANCAKE', '')
	const earnLabel = farm.dual ? farm.dual.earnLabel : 'COUPON'

	const farmAPR = farm.apr && farm.apr.toLocaleString('en-US', { maximumFractionDigits: 2 })

	const liquidityUrlPathParts = getLiquidityUrlPathParts({
		quoteTokenAddress: farm.quoteToken.address,
		tokenAddress: farm.token.address,
	})
	const addLiquidityUrl = `${BASE_ADD_LIQUIDITY_URL}/${liquidityUrlPathParts}`
	const lpAddress = farm.lpAddresses[process.env.REACT_APP_CHAIN_ID]

	return (
		<FCard>
			{farm.token.symbol === 'COUPON' && <StyledCardAccent />}
			<CardHeading
				lpLabel={lpLabel}
				multiplier={farm.multiplier}
				isCommunityFarm={farm.isCommunity}
				farmImage={farmImage}
				tokenSymbol={farm.token.symbol}
				single={single}
				withdraw100fee={withdraw100fee}
			/>
			{!removed && (
				<Flex justifyContent="space-between" alignItems="center">
					<Text>{t('APR')}:</Text>
					<Text bold style={{ display: 'flex', alignItems: 'center' }}>
						{farm.apr ? (
							<>
								<ApyButton
									lpLabel={lpLabel}
									addLiquidityUrl={addLiquidityUrl}
									couponPrice={couponPrice}
									apr={farm.apr}
								/>
								{farmAPR}%
							</>
						) : (
							<Skeleton height={24} width={80} />
						)}
					</Text>
				</Flex>
			)}
			<Flex justifyContent="space-between">
				<Text>{t('Earn')}:</Text>
				<Text bold>{earnLabel}</Text>
			</Flex>

			{farm.isWithdrawFee100 && (
				<div style={{marginTop: 8}}>
					<Text>* 100% Withdrawal Fee</Text>
				</div>
			)}

			<CardActionsContainer farm={farm} account={account} addLiquidityUrl={addLiquidityUrl} />
			<Divider />
			<ExpandableSectionButton
				onClick={() => setShowExpandableSection(!showExpandableSection)}
				expanded={showExpandableSection}
			/>
			<ExpandingWrapper expanded={showExpandableSection}>
				<DetailsSection
					single={single}
					removed={removed}
					bscScanAddress={`https://bscscan.com/address/${
						farm.lpAddresses[process.env.REACT_APP_CHAIN_ID]
					}`}
					infoAddress={`https://pancakeswap.info/pair/${lpAddress}`}
					totalValueFormatted={totalValueFormatted}
					lpLabel={lpLabel}
					addLiquidityUrl={addLiquidityUrl}
					myValueFormatted={myValueFormatted}
				/>
			</ExpandingWrapper>
		</FCard>
	)
}

export default FarmCard
