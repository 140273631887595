import BigNumber from 'bignumber.js'
import erc20 from 'config/abi/erc20.json'
import cafeteriaV2Abit from 'config/abi/cafeteriaV2.json'
import multicall from 'utils/multicall'
import { BIG_TEN } from 'utils/bigNumber'
import { getAddress, getCafeteriaV2Address } from 'utils/addressHelpers'
import { FarmConfig } from 'config/constants/types'
import { DEFAULT_TOKEN_DECIMAL } from 'config'

const fetchFarms = async (farmsToFetch: FarmConfig[]) => {
	const data = await Promise.all(
		farmsToFetch.map(async (farmConfig) => {
			const lpAddress = getAddress(farmConfig.lpAddresses)
			// console.log(lpAddress)
			const balanceAddress = farmConfig.isSinglePool ? getCafeteriaV2Address() : lpAddress
			const calls = [
				// Balance of token in the LP contract
				{
					address: getAddress(farmConfig.token.address),
					name: 'balanceOf',
					params: [balanceAddress],
				},
				// Balance of quote token on LP contract
				{
					address: getAddress(farmConfig.quoteToken.address),
					name: 'balanceOf',
					params: [balanceAddress],
				},
				// Balance of LP tokens in the master chef contract
				{
					address: lpAddress,
					name: 'balanceOf',
					params: [getCafeteriaV2Address()],
				},
				// Total supply of LP tokens
				{
					address: lpAddress,
					name: 'totalSupply',
				},
				// Token decimals
				{
					address: getAddress(farmConfig.token.address),
					name: 'decimals',
				},
				// Quote token decimals
				{
					address: getAddress(farmConfig.quoteToken.address),
					name: 'decimals',
				},
			]

			const [
				tokenBalanceLP,
				quoteTokenBalanceLP,
				lpTokenBalanceMC,
				lpTotalSupply,
				tokenDecimals,
				quoteTokenDecimals,
			] = await multicall(erc20, calls)

			// Ratio in % a LP tokens that are in staking, vs the total number in circulation
			const lpTokenRatio = new BigNumber(lpTokenBalanceMC).div(new BigNumber(lpTotalSupply))

			// Total value in staking in quote token value
			const lpTotalInQuoteToken = new BigNumber(quoteTokenBalanceLP)
				.div(farmConfig.isSinglePool ? BIG_TEN.pow(quoteTokenDecimals) : DEFAULT_TOKEN_DECIMAL)
				.times(new BigNumber(2))
				.times(farmConfig.isSinglePool ? 1 : lpTokenRatio)
				.div(farmConfig.isSinglePool ? 2 : 1)

			// Amount of token in the LP that are considered staking (i.e amount of token * lp ratio)
			const tokenAmount = new BigNumber(tokenBalanceLP)
				.div(BIG_TEN.pow(tokenDecimals))
				.times(lpTokenRatio)
			const quoteTokenAmount = new BigNumber(quoteTokenBalanceLP)
				.div(BIG_TEN.pow(quoteTokenDecimals))
				.times(lpTokenRatio)

			const [info, totalAllocPoint] = await multicall(cafeteriaV2Abit, [
				{
					address: getCafeteriaV2Address(),
					name: 'poolInfo',
					params: [farmConfig.pid],
				},
				{
					address: getCafeteriaV2Address(),
					name: 'totalAllocPoint',
				},
			])

			const allocPoint = new BigNumber(info.allocPoint._hex)
			const poolWeight = allocPoint.div(new BigNumber(totalAllocPoint))

			// console.log(farmConfig.pid, balanceAddress, 'quoteTokenBalanceLP:', quoteTokenBalanceLP.toString())
			// console.log(farmConfig.pid, balanceAddress, 'lpTotalSupply:', lpTotalSupply.toString())
			const valueInQuotePer1LP = new BigNumber(quoteTokenBalanceLP)
				.div(lpTotalSupply)
				.times(new BigNumber(2)) // จำนวน Quote Tokenx2 ต่อ LP
			// console.log("Quote LP", getAddress(farmConfig.lpAddresses), getAddress(farmConfig.quoteToken.address), quoteTokenBalanceLP.toString());

			return {
				...farmConfig,
				tokenAmount: tokenAmount.toJSON(),
				quoteTokenAmount: quoteTokenAmount.toJSON(),
				lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
				lpTotalInQuoteToken: lpTotalInQuoteToken.toJSON(),
				tokenPriceVsQuote: new BigNumber(quoteTokenBalanceLP).div(tokenBalanceLP).toJSON(),
				poolWeight: poolWeight.toJSON(),
				multiplier: `${allocPoint.div(100).toString()}X`,
				valueInQuotePer1LP: valueInQuotePer1LP.toJSON(),
			}
		}),
	)
	return data
}

export default fetchFarms
