import BigNumber from 'bignumber.js/bignumber'
import { BIG_TEN } from 'utils/bigNumber'
import { getCafeteriaV2Contract, getMasterchefContract } from 'utils/contractHelpers'

BigNumber.config({
	EXPONENTIAL_AT: 1000,
	DECIMAL_PLACES: 80,
})

export const BSC_BLOCK_TIME = 3

// COUPON_PER_BLOCK details
// COUPON_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.

export const COUPON_PER_BLOCK_V2 = new BigNumber(
	window.localStorage.getItem('COUPON_PER_BLOCK_V2') || 0,
)
export const BLOCKS_PER_YEAR = new BigNumber((60 / BSC_BLOCK_TIME) * 60 * 24 * 365) // 10512000
export const BASE_URL = 'https://www.foodcourt.finance'
export const BASE_EXCHANGE_URL = 'https://exchange.foodcourt.finance'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_EXCHANGE_URL}/#/add`
export const BASE_LIQUIDITY_POOL_URL = `${BASE_EXCHANGE_URL}/#/pool`
export const BASE_BSC_SCAN_URL = 'https://bscscan.com'
export const LOTTERY_MAX_NUMBER_OF_TICKETS = 50
export const LOTTERY_TICKET_PRICE = 1
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 200000

async function loadCouponPerBlockV2() {
	let realCouponPerBlock = await getCafeteriaV2Contract().methods.couponPerBlock().call()
	realCouponPerBlock = new BigNumber(realCouponPerBlock).div(DEFAULT_TOKEN_DECIMAL)
	console.log('COUPON PER BLOCK V2', realCouponPerBlock.toString())
	if (realCouponPerBlock.toString() !== COUPON_PER_BLOCK_V2.toString()) {
		window.localStorage.setItem('COUPON_PER_BLOCK_V2', realCouponPerBlock.toString())
		window.location.reload()
	}
	return realCouponPerBlock
}

loadCouponPerBlockV2()
