const rsafe2wsafe = {
  // "0xf37f180fBbA29681AcDcD7dca13F37c64eAB3272": "0xB1F5E032bE42097553DCEA281707BA3f9B6553ce",
  "0x3e45fd22cc219f640b3a608e274f5e0a5a6256a5": "0x422d0a431d8fb752e3697e90ba04b3324ea0cb4a",
  "0xeA760c8de4ce92e34cc3BD8b4ad9a85C13a91e6C": "0xa3863434a1Fc699185b3E6809a933056D1178366",
  "0xA651fdAa684B704C8e64eBbb71413245C01f52a1": "0x40733aBc9Acb7d48Caa632ee83E4e7B3d0008d9D",

	"0xcc42724c6683b7e57334c4e856f4c9965ed682bd": "0x032574B64Bf6fa42951f836CC8c5099d1C5747d3"
}

export default rsafe2wsafe;
