import React from 'react'
import styled from 'styled-components'
import { Text, Button, Input, InputProps, Flex, Link } from '../../uikit-foodcourt'
import { useTranslation } from 'contexts/Localization'

interface ModalInputProps {
	max: string
	symbol: string
	onSelectMax?: () => void
	onChange: (e: React.FormEvent<HTMLInputElement>) => void
	placeholder?: string
	value: string
	addLiquidityUrl?: string
	inputTitle?: string
}

const getBoxShadow = ({ isWarning = false, theme }) => {
	if (isWarning) {
		return theme.shadows.warning
	}

	return theme.shadows.inset
}

const StyledTokenInput = styled.div<InputProps>`
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.colors.input};
	border-radius: 16px;
	box-shadow: ${getBoxShadow};
	color: ${({ theme }) => theme.colors.text};
	padding: 8px 16px 8px 0;
	width: 100%;
`

const StyledInput = styled(Input)`
	box-shadow: none;
	width: 60px;
	margin: 0 8px;
	padding: 0 8px;

	${({ theme }) => theme.mediaQueries.xs} {
		width: 80px;
	}

	${({ theme }) => theme.mediaQueries.sm} {
		width: auto;
	}
`

const StyledErrorMessage = styled(Text)`
	position: absolute;
	bottom: -22px;
	a {
		display: inline;
	}
`

const ModalInput: React.FC<ModalInputProps> = ({
	max,
	symbol,
	onChange,
	onSelectMax,
	value,
	addLiquidityUrl,
	inputTitle,
}) => {
	const { t } = useTranslation()
	const isBalanceZero = max === '0' || !max

	const displayBalance = (balance: string) => {
		if (isBalanceZero) {
			return '0'
		}
		const balanceNumber = Number(balance)
		if (balanceNumber > 0 && balanceNumber < 0.0001) {
			return balanceNumber.toLocaleString(undefined, { maximumFractionDigits: 20 })
		}
		return balanceNumber.toLocaleString()
	}

	return (
		<div style={{ position: 'relative' }}>
			<StyledTokenInput isWarning={isBalanceZero}>
				<Flex justifyContent="space-between" pl="16px">
					<Text fontSize="14px">{inputTitle}</Text>
					<Text fontSize="14px">
						{t('Balance')}: {displayBalance(max)}
					</Text>
				</Flex>
				<Flex alignItems="flex-end" justifyContent="space-around">
					<StyledInput
						pattern="^[0-9]*[.,]?[0-9]*$"
						inputMode="decimal"
						step="any"
						min="0"
						onChange={onChange}
						placeholder="0"
						value={value}
					/>
					<Button scale="sm" onClick={onSelectMax} mr="8px">
						{t('Max')}
					</Button>
					<Text fontSize="16px">{symbol}</Text>
				</Flex>
			</StyledTokenInput>
			{/* {isBalanceZero && (
				<StyledErrorMessage fontSize="14px" color="failure">
					No tokens to stake:{' '}
					<Link fontSize="14px" bold={false} href={addLiquidityUrl} external color="failure">
						{t('get')} {symbol}
					</Link>
				</StyledErrorMessage>
			)} */}
		</div>
	)
}

export default ModalInput
