import React from 'react'
import Svg from '../../../components/Svg/Svg'
import { SvgProps } from '../../../components/Svg/types'

interface LogoProps extends SvgProps {
	isDark: boolean
}

const Logo: React.FC<LogoProps> = ({ isDark, ...props }) => {
	const textColor = isDark ? '#FFFFFF' : '#000000'
	return (
		<img
			src="/images/logo-foodcourt.svg"
			alt="foodcourt"
			className="nav-logo _mgl-16px _mgt-24px-sm _mgt-0px"
		/>
	)
}

export default React.memo(Logo, (prev, next) => prev.isDark === next.isDark)
