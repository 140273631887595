import React, { useState, useRef, useEffect } from 'react'
import { Button, Skeleton } from '../../../../../uikit-foodcourt'
import BigNumber from 'bignumber.js'
import { FarmWithStakedValue } from 'views/Farms/components/FarmCard/FarmCard'
import { getBalanceNumber } from 'utils/formatBalance'
import { useHarvest } from 'hooks/useHarvest'
import { useTranslation } from 'contexts/Localization'
import { usePriceCouponBusd } from 'state/hooks'
import { useCountUp } from 'react-countup'

import {
	ActionContainer,
	ActionTitles,
	Title,
	Subtle,
	ActionContent,
	Earned,
	Staked,
} from './styles'

interface HarvestActionProps extends FarmWithStakedValue {
	userDataReady: boolean
}

const HarvestAction: React.FunctionComponent<HarvestActionProps> = ({
	pid,
	userData,
	userDataReady,
	isWithdrawFee100,
	token,
}) => {
	const earningsBigNumber = new BigNumber(userData.earnings)
	const couponPrice = usePriceCouponBusd()
	let earnings = 0
	let earningsBusd = 0
	let displayBalance = userDataReady ? earnings.toLocaleString() : <Skeleton width={60} />

	// If user didn't connect wallet default balance will be 0
	if (!earningsBigNumber.isZero()) {
		earnings = getBalanceNumber(earningsBigNumber)
		earningsBusd = new BigNumber(earnings).multipliedBy(couponPrice).toNumber()
		displayBalance = earnings.toLocaleString()
	}

	const [pendingTx, setPendingTx] = useState(false)
	const { onReward } = useHarvest(pid)
	const { t } = useTranslation()

	const { countUp, update } = useCountUp({
		start: 0,
		end: earningsBusd,
		duration: 1,
		separator: ',',
		decimals: 3,
	})
	const updateValue = useRef(update)

	useEffect(() => {
		updateValue.current(earningsBusd)
	}, [earningsBusd, updateValue])

	return (
		<ActionContainer>
			<ActionTitles>
				<Title>COUPON </Title>
				<Subtle>{t('EARNED')}</Subtle>
			</ActionTitles>
			<ActionContent>
				<div>
					<Earned>{displayBalance}</Earned>
					{countUp > 0 && <Staked>~{countUp}USD</Staked>}
				</div>
				<Button
					disabled={!earnings || pendingTx || !userDataReady}
					onClick={async () => {
						if (isWithdrawFee100 && !window.confirm(`IMPORTANT: This is a 100% withdrawal fee pool, you will lose all your staked ${token.symbol} in exchange for ${displayBalance} COUPON`)) return;

						setPendingTx(true)
						await onReward()
						setPendingTx(false)
					}}
					ml="4px"
				>
					{ isWithdrawFee100 ? 'Harvest and Destroy!' : t('Harvest')}
				</Button>
			</ActionContent>
		</ActionContainer>
	)
}

export default HarvestAction
