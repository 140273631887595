export default {
	masterChef: {
		97: '0x0a79Fcc425029660f27F23F0826Cb909A5172455',
		56: '0xe43B7C5c4c2DF51306CCEb7cBc4b2fcC038874F1',
	},
	sousChef: {
		97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
		56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
	},
	cafeteriaV2: {
		97: '0x0a79Fcc425029660f27F23F0826Cb909A5172455',
		56: '0x07b48c393CabABf6458E72d57b7CcD8f73E94c03',
	},
	multiCall: {
		56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
		97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
	},
}
