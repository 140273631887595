import React, { useMemo } from 'react'
import {
	CheckmarkCircleIcon,
	ErrorIcon,
	Flex,
	LinkExternal,
	Text,
	Modal,
	Button,
} from '../../index'

type ChainSelectModalProps = {
	onDismiss?: () => void
}

const defaultOnDismiss = () => null

const ChainSelectModal = ({ onDismiss = defaultOnDismiss }: ChainSelectModalProps) => (
	<Modal title="Select chain" onDismiss={onDismiss}>
		<div className="lo-6-md lo-12 _gg-12px">
			<div
				className="_pst-rlt _bgcl-primary-100 _bdrd-12px lo-12 _gg-12px _jtfit-ct _pdh-24px _pdt-48px _pdbt-16px _cs-pt _bgcl-primary-200-hover _w-100pct"
				style={{ minWidth: '200px' }}
			>
				<img width="60" height="60" src="/images/chain-select/BSC.svg" alt="BSC" />
				<div className="_fs-500 _cl-primary-900">BSC</div>
				<div className="_pst-asl _l-12px _t-12px _dp-f">
					<div className="_w-16px _h-16px _bgcl-positive-500 _bdrd-max" />
					<div className="_cl-positive-500 _mgl-8px _fw-600">Active</div>
				</div>
			</div>
			<a href="https://reix.foodcourt.finance">
				<div
					className="_pst-rlt _bgcl-primary-100 _bdrd-12px lo-12 _gg-12px _jtfit-ct _pdh-24px _pdt-48px _pdbt-16px _cs-pt _bgcl-primary-200-hover _w-100pct"
					style={{ minWidth: '200px' }}
				>
					<img width="60" height="60" src="/images/chain-select/REI.svg" alt="REI" />
					<div className="_fs-500 _cl-primary-900">REI</div>
					{/* <div className="_pst-asl _l-12px _t-12px _dp-f">
						<div className="_w-16px _h-16px _bgcl-positive-500 _bdrd-max" />
						<div className="_cl-positive-500 _mgl-8px _fw-600">Active</div>
					</div> */}
				</div>
			</a>
			<div
				className="_ptev-n _pst-rlt _bgcl-gray-100 _bdrd-12px lo-12 _gg-12px _jtfit-ct _pdh-24px _pdt-48px _pdbt-16px _cs-pt _bgcl-primary-200-hover _w-100pct"
				style={{ minWidth: '200px' }}
			>
				<img width="60" height="60" src="/images/chain-select/POLYGON.svg" alt="BSC" />
				<div className="_fs-500 _cl-gray-500">POLYGON</div>
				<div className="_pst-asl _l-12px _t-12px _dp-f">
					<div className="_cl-gray-500 _mgl-8px _fw-600">Coming soon</div>
				</div>
			</div>
		</div>
	</Modal>
)

export default ChainSelectModal
