import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { FarmWithStakedValue } from 'views/Farms/components/FarmCard/FarmCard'
import { useMatchBreakpoints } from '../../../../uikit-foodcourt'
import { useTranslation } from 'contexts/Localization'
import useDelayedUnmount from 'hooks/useDelayedUnmount'
import { useFarmUserV2 } from 'state/hooks'

import Apr, { AprProps } from './Apr'
import Farm, { FarmProps } from './Farm'
import Earned, { EarnedProps } from './Earned'
import Details from './Details'
import Multiplier, { MultiplierProps } from './Multiplier'
import Liquidity, { LiquidityProps } from './Liquidity'
import ActionPanel from './Actions/ActionPanel'
import CellLayout from './CellLayout'
import { DesktopColumnSchema, MobileColumnSchema } from '../types'
import BigNumber from 'bignumber.js'
import { getFullDisplayBalance } from 'utils/formatBalance'

export interface RowProps {
	apr: AprProps
	farm: FarmProps
	earned: EarnedProps
	multiplier: MultiplierProps
	liquidity: LiquidityProps
	details: FarmWithStakedValue
}

interface RowPropsWithLoading extends RowProps {
	userDataReady: boolean
}

const cells = {
	apr: Apr,
	farm: Farm,
	earned: Earned,
	details: Details,
	multiplier: Multiplier,
	liquidity: Liquidity,
}

const CellInner = styled.div`
	padding: 24px 0px;
	display: flex;
	width: 100%;
	align-items: center;
	padding-right: 8px;

	${({ theme }) => theme.mediaQueries.xl} {
		padding-right: 32px;
	}
`

const StyledTr = styled.tr`
	cursor: pointer;
	border-bottom: 2px solid ${({ theme }) => theme.colors.borderColor};
`

const EarnedMobileCell = styled.td`
	padding: 16px 0 24px 16px;
`

const AprMobileCell = styled.td`
	padding-top: 16px;
	padding-bottom: 24px;
`

const FarmMobileCell = styled.td`
	padding-top: 24px;
`

const Row: React.FunctionComponent<RowPropsWithLoading> = (props) => {
	const { details, userDataReady, apr } = props
	const { lpValueInUsd } = apr;
	const stakedBalance = useFarmUserV2(details.pid).stakedBalance.toNumber()
	const hasStakedAmount = !!stakedBalance
	const [actionPanelExpanded, setActionPanelExpanded] = useState(hasStakedAmount)
	const shouldRenderChild = useDelayedUnmount(actionPanelExpanded, 300)
	const { t } = useTranslation()

	const toggleActionPanel = () => {
		setActionPanelExpanded(!actionPanelExpanded)
	}

	useEffect(() => {
		setActionPanelExpanded(hasStakedAmount)
	}, [hasStakedAmount])

	const { isXl, isXs } = useMatchBreakpoints()

	const isMobile = !isXl
	const tableSchema = isMobile ? MobileColumnSchema : DesktopColumnSchema
	const columnNames = tableSchema.map((column) => column.name)

	const myValueFormatted = lpValueInUsd
		? `$${new BigNumber(getFullDisplayBalance(lpValueInUsd.times(stakedBalance), 18, 2))
				.toNumber()
				.toLocaleString(undefined, { maximumFractionDigits: 0 })}`
		: '-'

	const handleRenderRow = () => {
		if (!isXs) {
			return (
				<StyledTr onClick={toggleActionPanel}>
					{Object.keys(props).map((key) => {
						const columnIndex = columnNames.indexOf(key)
						if (columnIndex === -1) {
							return null
						}

						switch (key) {
							case 'details':
								return (
									<td key={key}>
										<CellInner>
											<CellLayout>
												<Details actionPanelToggled={actionPanelExpanded} />
											</CellLayout>
										</CellInner>
									</td>
								)
							case 'apr':
								return (
									<td key={key}>
										<CellInner>
											<CellLayout label={t('APR')}>
												<Apr {...props.apr} hideButton={isMobile} />
											</CellLayout>
										</CellInner>
									</td>
								)
							case 'farm':
								return (
									<td key={key}>
										<CellInner>
											<CellLayout>
												<Farm {...props.farm} isWithdrawFee100={props.details.isWithdrawFee100} />
											</CellLayout>
										</CellInner>
									</td>
								)
							default:
								return (
									<td key={key}>
										<CellInner>
											<CellLayout label={t(tableSchema[columnIndex].label)}>
												{React.createElement(cells[key], { ...props[key], userDataReady })}
											</CellLayout>
										</CellInner>
									</td>
								)
						}
					})}
				</StyledTr>
			)
		}

		return (
			<StyledTr onClick={toggleActionPanel}>
				<td>
					<tr>
						<FarmMobileCell>
							<CellLayout>
								<Farm {...props.farm} isWithdrawFee100={props.details.isWithdrawFee100} />
							</CellLayout>
						</FarmMobileCell>
					</tr>
					<tr>
						<EarnedMobileCell>
							<CellLayout label={t('Earned')}>
								<Earned {...props.earned} userDataReady={userDataReady} />
							</CellLayout>
						</EarnedMobileCell>
						<AprMobileCell>
							<CellLayout label={t('APR')}>
								<Apr {...props.apr} hideButton />
							</CellLayout>
						</AprMobileCell>
					</tr>
				</td>
				<td>
					<CellInner>
						<CellLayout>
							<Details actionPanelToggled={actionPanelExpanded} />
						</CellLayout>
					</CellInner>
				</td>
			</StyledTr>
		)
	}

	// props.details.isWithdrawFee100
	// console.log(props)

	return (
		<>
			{handleRenderRow()}
			{shouldRenderChild && (
				<tr>
					<td colSpan={6}>
						<ActionPanel
							{...props}
							myValueFormatted={myValueFormatted}
							expanded={actionPanelExpanded}
						/>
					</td>
				</tr>
			)}
		</>
	)
}

export default Row
