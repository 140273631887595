import React from 'react'
import ChainSelectModal from './ChainSelectModal'
import { useModal } from '../../index'

const ChainSelectButton = ({ theme = 'white' }: { theme?: 'white' | 'primary' }): JSX.Element => {
	const [onPresentChainSelect] = useModal(<ChainSelectModal />)

	const style =
		theme === 'white'
			? { backgroundColor: 'white', boxShadow: '0px 2px 6px rgba(0,0,0,0.1)' }
			: { backgroundColor: 'var(--color-primary-100)' }

	return (
		<>
			<div id="chain-select-button" onClick={onPresentChainSelect}>
				<div
					className="_dp-f _alit-ct _pdr-12px _bdrd-max _bgcl-primary-100-hover _cs-pt"
					style={style}
				>
					<img
						width="30"
						height="30"
						src={`/images/chain-select/${process.env.REACT_APP_CHAIN_NAME}.svg`}
						alt="BSC"
						className="_dp-b _bdw-2px _bdrd-max"
						style={{ borderColor: 'var(--color-primary-100)' }}
					/>
					<div className="_dp-f _alit-ct _mgl-4px">
						<div className="_w-8px _h-8px _mgr-4px _bgcl-positive-500 _bdrd-max" />
						<div className="_cl-primary-800 _fw-800">{process.env.REACT_APP_CHAIN_NAME}</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default ChainSelectButton
