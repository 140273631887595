import styled from 'styled-components'
import Box from '../Box/Box'
import Input from '../Input/Input'
import { BalanceInputProps } from './types'

export const StyledBalanceInput = styled(Box)<{ isWarning: BalanceInputProps['isWarning'] }>`
	background-color: ${({ theme }) => theme.colors.input};
	border: 1px solid ${({ theme }) => theme.colors.inputSecondary};
	border-radius: 16px;
	box-shadow: ${({ theme, isWarning }) => theme.shadows[isWarning ? 'warning' : 'inset']};
	padding: 8px 16px;
`

export const StyledInput = styled(Input)`
	background: transparent;
	border-radius: 0;
	box-shadow: none;
	padding-left: 0;
	padding-right: 0;
	text-align: right;

	::placeholder {
		color: ${({ theme }) => theme.colors.textSubtle};
	}

	&:focus:not(:disabled) {
		box-shadow: none;
	}
`
