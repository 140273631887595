import React from 'react'
import styled, { keyframes } from 'styled-components'
import PanIcon from './PanIcon'
import { SpinnerProps } from './types'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const Container = styled.div`
	position: relative;
`

const FoodSpin = styled(PanIcon)`
	position: absolute;
	top: -64px;
	left: -64px;
	animation: ${rotate} 2s linear infinite;
	transform: translate3d(0, 0, 0);
`

const Spinner: React.FC<SpinnerProps> = ({ size = 128 }) => (
	<Container>
		<FoodSpin width={`${size}px`} />
	</Container>
)

export default Spinner
