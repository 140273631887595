import BigNumber from 'bignumber.js'
import { DEFAULT_TOKEN_DECIMAL } from 'config'
import { ethers } from 'ethers'
import { BIG_TEN, BIG_ZERO } from './bigNumber'

export const approve = async (lpContract, masterChefContract, account) => {
	return lpContract.methods
		.approve(masterChefContract.options.address, ethers.constants.MaxUint256)
		.send({ from: account })
}

export const stake = async (masterChefContract, pid, amount, account) => {
	return masterChefContract.methods
		.deposit(pid, new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString())
		.send({ from: account, gas: 200000 })
		.on('transactionHash', (tx) => {
			return tx.transactionHash
		})
}

export const sousStake = async (sousChefContract, amount, decimals = 18, account) => {
	return sousChefContract.methods
		.deposit(new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString())
		.send({ from: account, gas: 200000 })
		.on('transactionHash', (tx) => {
			return tx.transactionHash
		})
}

export const sousStakeBnb = async (sousChefContract, amount, account) => {
	return sousChefContract.methods
		.deposit()
		.send({
			from: account,
			gas: 200000,
			value: new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString(),
		})
		.on('transactionHash', (tx) => {
			return tx.transactionHash
		})
}

export const unstake = async (
	masterChefContract,
	pid,
	amount,
	account,
	isWithdrawFee100 = false,
) => {
	if (false && pid === 0) {
		return masterChefContract.methods
			.leaveStaking(new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString())
			.send({ from: account, gas: 200000 })
			.on('transactionHash', (tx) => {
				return tx.transactionHash
			})
	}

	if (isWithdrawFee100) {
		return masterChefContract.methods
			.emergencyWithdraw(pid)
			.send({ from: account, gas: 200000 })
			.on('transactionHash', (tx) => {
				return tx.transactionHash
			})
	}

	return masterChefContract.methods
		.withdraw(pid, new BigNumber(amount).times(DEFAULT_TOKEN_DECIMAL).toString())
		.send({ from: account, gas: 200000 })
		.on('transactionHash', (tx) => {
			return tx.transactionHash
		})
}

export const emergencyWithdraw = async (masterChefContract, pid, account) => {
	return masterChefContract.methods
		.emergencyWithdraw(pid)
		.send({ from: account, gas: 200000 })
		.on('transactionHash', (tx) => {
			return tx.transactionHash
		})
}

export const sousUnstake = async (sousChefContract, amount, decimals, account) => {
	return sousChefContract.methods
		.withdraw(new BigNumber(amount).times(BIG_TEN.pow(decimals)).toString())
		.send({ from: account, gas: 200000 })
		.on('transactionHash', (tx) => {
			return tx.transactionHash
		})
}

export const sousEmergencyUnstake = async (sousChefContract, account) => {
	return sousChefContract.methods
		.emergencyWithdraw()
		.send({ from: account })
		.on('transactionHash', (tx) => {
			return tx.transactionHash
		})
}

export const harvest = async (masterChefContract, pid, account) => {
	return masterChefContract.methods
		.deposit(pid, '0')
		.send({ from: account, gas: 200000 })
		.on('transactionHash', (tx) => {
			return tx.transactionHash
		})
}

export const harvestAll = async (masterChefContract, account) => {
	return masterChefContract.methods
		.harvestAll()
		.send({ from: account, gas: 1000000 })
		.on('transactionHash', (tx) => {
			return tx.transactionHash
		})
}

export const soushHarvest = async (sousChefContract, account) => {
	return sousChefContract.methods
		.deposit('0')
		.send({ from: account, gas: 200000 })
		.on('transactionHash', (tx) => {
			return tx.transactionHash
		})
}

export const soushHarvestBnb = async (sousChefContract, account) => {
	return sousChefContract.methods
		.deposit()
		.send({ from: account, gas: 200000, value: BIG_ZERO })
		.on('transactionHash', (tx) => {
			return tx.transactionHash
		})
}
