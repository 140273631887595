import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import {
	fetchFarmUserDataAsync,
	fetchFarmUserDataAsyncV2,
	updateUserStakedBalance,
	updateUserBalance,
} from 'state/actions'
import { stake, sousStake, sousStakeBnb } from 'utils/callHelpers'
import { useCafeteriaV2, useMasterchef, useSousChef } from './useContract'

export const useStake = (pid: number) => {
	const dispatch = useAppDispatch()
	const { account } = useWeb3React()
	const masterChefContract = useMasterchef()

	const handleStake = useCallback(
		async (amount: string) => {
			const txHash = await stake(masterChefContract, pid, amount, account)
			dispatch(fetchFarmUserDataAsync(account))
			console.info(txHash)
		},
		[account, dispatch, masterChefContract, pid],
	)

	return { onStake: handleStake }
}

export const useStakeV2 = (pid: number) => {
	const dispatch = useAppDispatch()
	const { account } = useWeb3React()
	const cafeteriaV2Contract = useCafeteriaV2()

	const handleStake = useCallback(
		async (amount: string) => {
			const txHash = await stake(cafeteriaV2Contract, pid, amount, account)
			dispatch(fetchFarmUserDataAsyncV2(account))
			console.info(txHash)
		},
		[account, dispatch, cafeteriaV2Contract, pid],
	)

	return { onStake: handleStake }
}

export const useSousStake = (sousId, isUsingBnb = false) => {
	const dispatch = useAppDispatch()
	const { account } = useWeb3React()
	const masterChefContract = useMasterchef()
	const sousChefContract = useSousChef(sousId)

	const handleStake = useCallback(
		async (amount: string, decimals: number) => {
			if (sousId === 0) {
				await stake(masterChefContract, 0, amount, account)
			} else if (isUsingBnb) {
				await sousStakeBnb(sousChefContract, amount, account)
			} else {
				await sousStake(sousChefContract, amount, decimals, account)
			}
			dispatch(updateUserStakedBalance(sousId, account))
			dispatch(updateUserBalance(sousId, account))
		},
		[account, dispatch, isUsingBnb, masterChefContract, sousChefContract, sousId],
	)

	return { onStake: handleStake }
}
