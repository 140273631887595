import React from 'react'
import Button from '../../components/Button/Button'
import Text from '../../components/Text/Text'
import LinkExternal from '../../components/Link/LinkExternal'
import Flex from '../../components/Box/Flex'
import { Modal } from '../Modal'
import CopyToClipboard from './CopyToClipboard'
import { connectorLocalStorageKey } from './config'

interface Props {
	account: string
	logout: () => void
	onDismiss?: () => void
}

const AccountModal: React.FC<Props> = ({ account, logout, onDismiss = () => null }) => (
	<Modal title="Your wallet" onDismiss={onDismiss}>
		<Text
			fontSize="20px"
			bold
			style={{
				whiteSpace: 'nowrap',
				overflow: 'hidden',
				textOverflow: 'ellipsis',
				marginBottom: '8px',
			}}
		>
			<div className="_bgcl-primary-100 _pd-12px _bdrd-8px _fs-500 _cl-primary-800">{account}</div>
		</Text>
		<div className="_dp-f _jtfct-fe">
			<CopyToClipboard toCopy={account}>Copy Address</CopyToClipboard>
		</div>
		<div className="_dp-f _jtfct-ct _mgt-32px">
			<button
				type="button"
				className="fc-button -accent _bdrd-max"
				onClick={() => {
					logout()
					window.localStorage.removeItem(connectorLocalStorageKey)
					onDismiss()
				}}
			>
				Logout
				<i className="fas fa-power-off _mgl-8px" />
			</button>
		</div>
	</Modal>
)

export default AccountModal
