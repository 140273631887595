import BigNumber from 'bignumber.js'
import { CampaignType, FarmConfig, Nft, PoolConfig, Team } from 'config/constants/types'

export type TranslatableText =
	| string
	| {
			key: string
			data?: {
				[key: string]: string | number
			}
	  }

export type SerializedBigNumber = string

export interface Farm extends FarmConfig {
	tokenAmountMc?: SerializedBigNumber
	quoteTokenAmountMc?: SerializedBigNumber
	tokenAmountTotal?: SerializedBigNumber
	quoteTokenAmountTotal?: SerializedBigNumber
	lpTotalInQuoteToken?: SerializedBigNumber
	lpTotalSupply?: SerializedBigNumber
	tokenPriceVsQuote?: SerializedBigNumber
	poolWeight?: SerializedBigNumber
	userData?: {
		allowance: string
		tokenBalance: string
		stakedBalance: string
		earnings: string
	}
	valueInQuotePer1LP?: SerializedBigNumber
}

export interface Pool extends PoolConfig {
	totalStaked?: BigNumber
	stakingLimit?: BigNumber
	startBlock?: number
	endBlock?: number
	apr?: number
	stakingTokenPrice?: number
	earningTokenPrice?: number
	isAutoVault?: boolean
	userData?: {
		allowance: BigNumber
		stakingTokenBalance: BigNumber
		stakedBalance: BigNumber
		pendingReward: BigNumber
	}
}

export interface Profile {
	userId: number
	points: number
	teamId: number
	nftAddress: string
	tokenId: number
	isActive: boolean
	username: string
	nft?: Nft
	team: Team
	hasRegistered: boolean
}

// Slices states

export interface FarmsState {
	data: Farm[]
	loadArchivedFarmsData: boolean
	userDataLoaded: boolean
}

export interface VaultFees {
	performanceFee: number
	callFee: number
	withdrawalFee: number
	withdrawalFeePeriod: number
}

export interface VaultUser {
	isLoading: boolean
	userShares: string
	cakeAtLastUserAction: string
	lastDepositedTime: string
	lastUserActionTime: string
}
export interface CakeVault {
	totalShares?: string
	pricePerFullShare?: string
	totalCakeInVault?: string
	estimatedCakeBountyReward?: string
	totalpendingCouponHarvest?: string
	fees?: VaultFees
	userData?: VaultUser
}

export interface PoolsState {
	data: Pool[]
	cakeVault: CakeVault
	userDataLoaded: boolean
}

export interface ProfileState {
	isInitialized: boolean
	isLoading: boolean
	hasRegistered: boolean
	data: Profile
}

export type TeamResponse = {
	0: string
	1: string
	2: string
	3: string
	4: boolean
}

export type TeamsById = {
	[key: string]: Team
}

export interface TeamsState {
	isInitialized: boolean
	isLoading: boolean
	data: TeamsById
}

export interface Achievement {
	id: string
	type: CampaignType
	address: string
	title: TranslatableText
	description?: TranslatableText
	badge: string
	points: number
}

export interface AchievementState {
	data: Achievement[]
}

// Block

export interface BlockState {
	currentBlock: number
	initialBlock: number
}

// Collectibles

export interface CollectiblesState {
	isInitialized: boolean
	isLoading: boolean
	data: {
		[key: string]: number[]
	}
}

// Predictions

export enum BetPosition {
	BULL = 'Bull',
	BEAR = 'Bear',
	HOUSE = 'House',
}

export enum PredictionStatus {
	INITIAL = 'initial',
	LIVE = 'live',
	PAUSED = 'paused',
	ERROR = 'error',
}

export interface Round {
	id: string
	epoch: number
	failed?: boolean
	startBlock: number
	startAt: number
	lockAt: number
	lockBlock: number
	lockPrice: number
	endBlock: number
	closePrice: number
	totalBets: number
	totalAmount: number
	bullBets: number
	bearBets: number
	bearAmount: number
	bullAmount: number
	position: BetPosition
	bets?: Bet[]
}

export interface Market {
	id: string
	paused: boolean
	epoch: number
}

export interface Bet {
	id?: string
	hash?: string
	amount: number
	position: BetPosition
	claimed: boolean
	user?: PredictionUser
	round: Round
}

export interface PredictionUser {
	id: string
	address: string
	block: number
	totalBets: number
	totalBNB: number
}

export interface RoundData {
	[key: string]: Round
}

export interface HistoryData {
	[key: string]: Bet[]
}

export interface BetData {
	[key: string]: {
		[key: string]: Bet
	}
}

export enum HistoryFilter {
	ALL = 'all',
	COLLECTED = 'collected',
	UNCOLLECTED = 'uncollected',
}

export interface PredictionsState {
	status: PredictionStatus
	isLoading: boolean
	isHistoryPaneOpen: boolean
	isChartPaneOpen: boolean
	isFetchingHistory: boolean
	historyFilter: HistoryFilter
	currentEpoch: number
	currentRoundStartBlockNumber: number
	intervalBlocks: number
	bufferBlocks: number
	minBetAmount: string
	lastOraclePrice: string
	rounds: RoundData
	history: HistoryData
	bets: BetData
}

// API Price State
export interface PriceApiList {
	/* eslint-disable camelcase */
	[key: string]: {
		name: string
		symbol: string
		price: string
		price_BNB: string
	}
}

export interface PriceApiListThunk {
	/* eslint-disable camelcase */
	[key: string]: number
}

export interface PriceApiResponse {
	/* eslint-disable camelcase */
	updated_at: string
	data: PriceApiList
}

export interface PriceApiThunk {
	/* eslint-disable camelcase */
	updated_at: string
	data: PriceApiListThunk
}

export interface PriceState {
	isLoading: boolean
	lastUpdated: string
	data: PriceApiListThunk
}

// Global state

export interface State {
	achievements: AchievementState
	block: BlockState
	farms: FarmsState
	farmsV2: FarmsState
	pools: PoolsState
	poolsV2: PoolsState
	predictions: PredictionsState
	profile: ProfileState
	teams: TeamsState
	collectibles: CollectiblesState
	prices: PriceState
}
