import BigNumber from 'bignumber.js'
import React, { useCallback, useMemo, useState } from 'react'
import { Button, Modal, LinkExternal, Text, TextProps } from '../../../uikit-foodcourt'
import ModalActions from 'components/ModalActions'
import ModalInput from 'components/ModalInput'
import { useTranslation } from 'contexts/Localization'
import { getFullDisplayBalance } from 'utils/formatBalance'
import { BIG_TEN } from 'utils/bigNumber'
import { DEFAULT_TOKEN_DECIMAL } from 'config'

interface DepositModalProps {
	max: BigNumber
	onConfirm: (amount: string) => void
	onDismiss?: () => void
	tokenName?: string
	addLiquidityUrl?: string
	isWithdrawFee100?: boolean
	decimals?: number
}

const DepositModal: React.FC<DepositModalProps> = ({
	max,
	onConfirm,
	onDismiss,
	tokenName = '',
	addLiquidityUrl,
	isWithdrawFee100,
	decimals,
}) => {
	const [val, setVal] = useState('')
	const [pendingTx, setPendingTx] = useState(false)
	const { t } = useTranslation()
	const fullBalance = useMemo(() => {
		return getFullDisplayBalance(max, decimals)
	}, [max])

	const valNumber = new BigNumber(val)
	const fullBalanceNumber = new BigNumber(fullBalance)

	const handleChange = useCallback(
		(e: React.FormEvent<HTMLInputElement>) => {
			if (e.currentTarget.validity.valid) {
				setVal(e.currentTarget.value.replace(/,/g, '.'))
			}
		},
		[setVal],
	)

	const handleSelectMax = useCallback(() => {
		setVal(fullBalance)
	}, [fullBalance, setVal])

	return (
		<Modal title={t('Stake LP tokens')} onDismiss={onDismiss}>
			<ModalInput
				value={val}
				onSelectMax={handleSelectMax}
				onChange={handleChange}
				max={fullBalance}
				symbol={tokenName}
				addLiquidityUrl={addLiquidityUrl}
				inputTitle={t('Stake')}
			/>
			<ModalActions>
				<Button variant="secondary" onClick={onDismiss} width="100%" disabled={pendingTx}>
					{t('Cancel')}
				</Button>
				<Button
					width="100%"
					disabled={
						pendingTx || !valNumber.isFinite() || valNumber.eq(0) || valNumber.gt(fullBalanceNumber)
					}
					onClick={async () => {
						if (
							isWithdrawFee100 &&
							!window.confirm(
								`WARNING: This is 100% withdrawal fee pool, You will lose all your ${tokenName} once you harvest your COUPON reward.`,
							)
						)
							return

						const adjustedVal = new BigNumber(val).times(BIG_TEN.pow(decimals)).div(DEFAULT_TOKEN_DECIMAL);

						setPendingTx(true)
						await onConfirm(adjustedVal.toString())
						setPendingTx(false)
						onDismiss()
					}}
				>
					{pendingTx ? t('Pending Confirmation') : t('Confirm')}
				</Button>
			</ModalActions>

			{isWithdrawFee100 ? (
				<Text style={{ color: 'hsl(9, 95%, 65%)', maxWidth: 'fit-content' }}>
					WARNING: This is 100% withdrawal fee pool, You will lose all your {tokenName} once you harvest your COUPON reward.
				</Text>
			) : (
				<LinkExternal href={addLiquidityUrl} style={{ alignSelf: 'center' }}>
					{t('Get')} {tokenName}
				</LinkExternal>
			)}
		</Modal>
	)
}

export default DepositModal
