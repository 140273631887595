import React from 'react'
import styled from 'styled-components'
import { useFarmUserV2 } from 'state/hooks'
import { useTranslation } from 'contexts/Localization'
import { Text, Image } from '../../../../uikit-foodcourt'
import { getBalanceNumber } from 'utils/formatBalance'

export interface FarmProps {
	label: string
	pid: number
	image: string
	single: boolean,
	isWithdrawFee100?: boolean
}

const IconImage = styled(Image)`
	width: 60px;
	height: 60px;

	${({ theme }) => theme.mediaQueries.sm} {
		width: 80px;
		height: 80px;
		max-width: 80px;
		max-height: 80px;
	}
`

const Container = styled.div`
	padding-left: 16px;
	display: flex;
	align-items: center;

	${({ theme }) => theme.mediaQueries.sm} {
		padding-left: 32px;
	}
`

const Farm: React.FunctionComponent<FarmProps> = ({ image, label, pid, single, isWithdrawFee100 }) => {
	const { stakedBalance } = useFarmUserV2(pid)
	const { t } = useTranslation()
	const rawStakedBalance = getBalanceNumber(stakedBalance)

	const handleRenderFarming = (): JSX.Element => {
		if (rawStakedBalance) {
			return (
				<Text color="secondary" fontSize="12px" bold>
					{t('FARMING')}
				</Text>
			)
		}

		return null
	}

	return (
		<Container>
			<IconImage src={single ? `/images/pools/${image}.svg` : `/images/farms/${image}.svg`} alt="icon" width={50} height={50} mr="8px" />
			<div>
				{handleRenderFarming()}
				<Text bold>{label}</Text>
				{isWithdrawFee100 && <Text style={{fontSize: 12}}>100% Withdrawal fee</Text>}
			</div>
		</Container>
	)
}

export default Farm
