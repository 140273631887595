import React from 'react'
import Heading from '../../components/Heading/Heading'
import {
	ModalBody,
	ModalHeader,
	ModalTitle,
	ModalContainer,
	ModalCloseButton,
	ModalBackButton,
} from './styles'
import { ModalProps } from './types'

const Modal: React.FC<ModalProps> = ({
	title,
	onDismiss,
	onBack,
	children,
	hideCloseButton = false,
	bodyPadding = '24px',
	headerBackground = 'transparent',
	minWidth = '320px',
	...props
}) => (
	<ModalContainer minWidth={minWidth} {...props}>
		<ModalHeader background={headerBackground}>
			<ModalTitle>
				{onBack && <ModalBackButton onBack={onBack} />}
				<div className="_fw-600 _fs-600">{title}</div>
			</ModalTitle>
			{!hideCloseButton && (
				<div
					onClick={() => onDismiss()}
					className="_dp-f _alit-ct _jtfct-ct _bdrd-max _bgcl-gray-100 _w-32px _h-32px _cs-pt _bgcl-gray-200-hover"
				>
					<i className="far fa-times" />
				</div>
			)}
		</ModalHeader>
		<ModalBody p={bodyPadding}>{children}</ModalBody>
	</ModalContainer>
)

export default Modal
