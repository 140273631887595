import React from 'react'
import styled from 'styled-components'
import { Tag, Flex, Heading, Image } from '../../../../uikit-foodcourt'
import { CommunityTag, CoreTag } from 'components/Tags'

export interface ExpandableSectionProps {
	lpLabel?: string
	multiplier?: string
	isCommunityFarm?: boolean
	farmImage?: string
	tokenSymbol?: string
	single?: boolean
	withdraw100fee?: boolean
}

const Wrapper = styled(Flex)`
	svg {
		margin-right: 4px;
	}
`

const MultiplierTag = styled(Tag)`
	margin-left: 4px;
`

const CardHeading: React.FC<ExpandableSectionProps> = ({
	lpLabel,
	multiplier,
	isCommunityFarm,
	farmImage,
	tokenSymbol,
	single=false,
	withdraw100fee=false,
}) => {
	return (
		<Wrapper justifyContent="space-between" alignItems="center" mb="12px">
			<Image src={single ? `/images/pools/${farmImage}.svg` : `/images/farms/${farmImage}.svg`} alt={tokenSymbol} width={96} height={96} />
			<Flex flexDirection="column" alignItems="flex-end">
				<Heading mb="4px">{lpLabel.split(' ')[0]}</Heading>
				<Flex justifyContent="center">
					{isCommunityFarm ? <CommunityTag /> : <CoreTag />}
					<MultiplierTag variant="secondary">{multiplier}</MultiplierTag>
				</Flex>
			</Flex>
		</Wrapper>
	)
}

export default CardHeading
