import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
	{
		pid: 0,
		lpSymbol: 'COUPON',
		imageName: 'coupon-coupon',
		lpAddresses: {
			97: '0x2B0642FB991c78455403d77438d673EC7b17c52A',
			56: '0x084bb94e93891D74579B54Ab63ED24C4ef9cd5Ef',
		},
		token: tokens.coupon,
		quoteToken: tokens.coupon,
		isSinglePool: true,
	},

	{
		pid: 1,
		lpSymbol: 'COUPON-BNB LP',
		imageName: 'coupon-bnb',
		lpAddresses: {
			97: '0x5aa60f4d2cc1208f53e12bce2828f0de28f4a670',
			56: '0x9d368BA7176F77b2c0abe129eEB1a47B58CDde7E',
		},
		token: tokens.coupon,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 2,
		lpSymbol: 'COUPON-BUSD LP',
		imageName: 'coupon-busd',
		lpAddresses: {
			97: '0xD7d929a315A6BA8f3A12F07437108c32100E2847',
			56: '0x52560eBc7920617c9b7328186D1F4A136eAF07F4',
		},
		token: tokens.coupon,
		quoteToken: tokens.busd,
	},
	{
		pid: 3,
		lpSymbol: 'BNB-BUSD LP',
		imageName: 'bnb-busd',
		lpAddresses: {
			97: '0xD7d929a315A6BA8f3A12F07437108c32100E2847',
			56: '0xd241257d8fC4AC288fDEDA03dFF18aF373d968c8',
		},
		token: tokens.wbnb,
		quoteToken: tokens.busd,
	},
	{
		pid: 4,
		lpSymbol: 'kKUB-BNB LP',
		imageName: 'kkub-bnb',
		lpAddresses: {
			97: '0xD7d929a315A6BA8f3A12F07437108c32100E2847',
			56: '0xCC32C1Ad924Ba80F96b526CABF088Ee7F7DD3799',
		},
		token: tokens.kkub,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 5,
		lpSymbol: 'kMATIC-BNB LP',
		imageName: 'kmatic-bnb',
		lpAddresses: {
			97: '0xD7d929a315A6BA8f3A12F07437108c32100E2847',
			56: '0xf51946D79BC438dDA6DC3E5B138C09c1949b38CC',
		},
		token: tokens.kmatic,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 6,
		lpSymbol: 'kMATIC-MATIC LP',
		imageName: 'kmatic-matic',
		lpAddresses: {
			97: '0xD7d929a315A6BA8f3A12F07437108c32100E2847',
			56: '0xd4ab4530F6d86605727591485812eBc258cd106d',
		},
		token: tokens.kmatic,
		quoteToken: tokens.matic,
	},
	{
		pid: 7,
		lpSymbol: 'kTUK-BNB LP',
		imageName: 'ktuk-bnb',
		lpAddresses: {
			97: '0xD7d929a315A6BA8f3A12F07437108c32100E2847',
			56: '0xae4d28a77676663528fb3590332744988bd67284',
		},
		token: tokens.ktuk,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 8,
		lpSymbol: 'wMMP-BNB LP',
		imageName: 'wmmp-bnb',
		lpAddresses: {
			97: '0xD7d929a315A6BA8f3A12F07437108c32100E2847',
			56: '0xA360Fa1De4C15ea54d61a923a285126cD5482723',
		},
		token: tokens.wmmp,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 9,
		lpSymbol: 'wMMP-BUSD LP',
		imageName: 'wmmp-busd',
		lpAddresses: {
			97: '0xD7d929a315A6BA8f3A12F07437108c32100E2847',
			56: '0xf71D708Fb2f342828e0a77C4AA8c6018709C18B0',
		},
		token: tokens.wmmp,
		quoteToken: tokens.busd,
	},
	{
		pid: 10,
		lpSymbol: 'wSAFEMOON-BNB LP',
		imageName: 'wsafemoon-bnb',
		lpAddresses: {
			97: '0xD7d929a315A6BA8f3A12F07437108c32100E2847',
			56: '0xcEb52cA20582a28255E7E66Fea42da3Cc285E1Ee',
		},
		token: tokens.wsafemoon,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 11,
		lpSymbol: 'wSAFEMARS-BNB LP',
		imageName: 'wsafemars-bnb',
		lpAddresses: {
			97: '0xD7d929a315A6BA8f3A12F07437108c32100E2847',
			56: '0x45eA9Dfad5e95bEE31eba9439aAc9D38c79f1ed2',
		},
		token: tokens.wsafemars,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 12,
		lpSymbol: 'USDT-BUSD LP',
		imageName: 'usdt-busd',
		lpAddresses: {
			97: '0xD7d929a315A6BA8f3A12F07437108c32100E2847',
			56: '0x2e3FDF8474F02D75933cA001BC5A70f6707DdB80',
		},
		token: tokens.usdt,
		quoteToken: tokens.busd,
	},
	{
		pid: 13,
		lpSymbol: 'kKUB',
		imageName: 'kkub-coupon',
		lpAddresses: {
			97: '0xf37f180fBbA29681AcDcD7dca13F37c64eAB3272',
			56: '0x65270ad4d7d33D797EA8d2D4Ab7f3427E4C848B1',
		},
		token: tokens.kkub,
		quoteToken: tokens.kkub,
		isSinglePool: true,
	},
	{
		pid: 14,
		lpSymbol: 'kTUK',
		imageName: 'ktuk-coupon',
		lpAddresses: {
			97: '0xf37f180fBbA29681AcDcD7dca13F37c64eAB3272',
			56: '0xFAa6c12CDeB3A63085fD78644756566c69778c53',
		},
		token: tokens.ktuk,
		quoteToken: tokens.ktuk,
		isSinglePool: true,
	},
	{
		pid: 15,
		lpSymbol: 'kMATIC',
		imageName: 'kmatic-coupon',
		lpAddresses: {
			97: '0xf37f180fBbA29681AcDcD7dca13F37c64eAB3272',
			56: '0x032574B64Bf6fa42951f836CC8c5099d1C5747d3',
		},
		token: tokens.kmatic,
		quoteToken: tokens.kmatic,
		isSinglePool: true,
	},
	{
		pid: 22,
		lpSymbol: 'kUSDC-BUSD LP',
		imageName: 'kusdc-busd',
		lpAddresses: {
			97: '',
			56: '0xbaccB690c22359E310eb12C72955a0572A597393',
		},
		token: tokens.kusdc,
		quoteToken: tokens.busd,
	},
	{
		pid: 23,
		lpSymbol: 'SCZ-BNB LP',
		imageName: 'scz-bnb',
		lpAddresses: {
			97: '',
			56: '0xaD468bCBcB33037e061ed5eC905ddEA06cAFC67F',
		},
		token: tokens.scz,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 24,
		lpSymbol: 'GOONG-BNB LP',
		imageName: 'goong-bnb',
		lpAddresses: {
			97: '',
			56: '0x1Ddc47ca70b68CA6Bf7dEBd4209934e6364899A5',
		},
		token: tokens.goong,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 26,
		lpSymbol: 'LOREM-BNB LP',
		imageName: 'lorem-bnb',
		lpAddresses: {
			97: '',
			56: '0x4ac9c352954b2bcbf0affa34363cbdc1ea2ac242',
		},
		token: tokens.lorem,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 27,
		lpSymbol: 'LOREM',
		imageName: 'lorem-lorem',
		lpAddresses: {
			97: '',
			56: '0x401d9c80b8f349c462c1f85f16f0e7158216d98b',
		},
		token: tokens.lorem,
		quoteToken: tokens.lorem,
		isSinglePool: true,
	},
	{
		pid: 29,
		lpSymbol: 'UST-BUSD LP',
		imageName: 'ust-busd',
		lpAddresses: {
			97: '',
			56: '0x7f6b01c51fa055ac87276a3fb0d85c197b47ba23',
		},
		token: tokens.ust,
		quoteToken: tokens.busd,
	},
	{
		pid: 28,
		lpSymbol: 'USDC-BUSD LP',
		imageName: 'usdc-busd',
		lpAddresses: {
			97: '',
			56: '0x563b905c8f398ac117c8acb5b747d6cb7b14903f',
		},
		token: tokens.usdc,
		quoteToken: tokens.busd,
	},
	{
		pid: 30,
		lpSymbol: 'VAI-BUSD LP',
		imageName: 'vai-busd',
		lpAddresses: {
			97: '',
			56: '0xfb215c506d8f547058e026bbd4f5715639799aa3',
		},
		token: tokens.vai,
		quoteToken: tokens.busd,
	},
	{
		pid: 31,
		lpSymbol: 'DAI-BUSD LP',
		imageName: 'dai-busd',
		lpAddresses: {
			97: '',
			56: '0x7f4dfdd554cbb709685009f575d489ef5bb24a20',
		},
		token: tokens.dai,
		quoteToken: tokens.busd,
	},
	{
		pid: 42,
		lpSymbol: 'TUSD-BUSD LP',
		imageName: 'tusd-busd',
		lpAddresses: {
			97: '',
			56: '0xf2e3ae8366a34c310614e65abf210d41f1646cb2',
		},
		token: tokens.tusd,
		quoteToken: tokens.busd,
	},
	{
		pid: 44,
		lpSymbol: 'KUSD-BUSD LP',
		imageName: 'kusd-busd',
		lpAddresses: {
			97: '',
			56: '0xe2601845f8978615f6f721aede0f8e23b292c0bc',
		},
		token: tokens.kusd,
		quoteToken: tokens.busd,
	},
	{
		pid: 45,
		lpSymbol: 'USDN-BUSD LP',
		imageName: 'usdn-busd',
		lpAddresses: {
			97: '',
			56: '0x15a546013ca850849fbc204a45038f35fcbb156d',
		},
		token: tokens.usdn,
		quoteToken: tokens.busd,
	},
	{
		pid: 47,
		lpSymbol: 'ttUSD-BUSD LP',
		imageName: 'ttusd-busd',
		lpAddresses: {
			97: '',
			56: '0x38f1d86facf4f4f971c80f7817c47b170c8a6c07',
		},
		token: tokens.ttusd,
		quoteToken: tokens.busd,
	},
	{
		pid: 48,
		lpSymbol: 'WUSD-BUSD LP',
		imageName: 'wusd-busd',
		lpAddresses: {
			97: '',
			56: '0xc91e1596908f825b485a85ec43e01bb14ff44b3b',
		},
		token: tokens.wusd,
		quoteToken: tokens.busd,
	},
	{
		pid: 32,
		lpSymbol: 'BTCB-BNB LP',
		imageName: 'btcb-bnb',
		lpAddresses: {
			97: '',
			56: '0x7e837c13b54251dee0199cc2f5ac16b2b2c7a37d',
		},
		token: tokens.btcb,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 33,
		lpSymbol: 'KETH-ETH LP',
		imageName: 'keth-eth',
		lpAddresses: {
			97: '',
			56: '0xce6befb6102a3cd94815c7145303703839f47843',
		},
		token: tokens.keth,
		quoteToken: tokens.eth,
	},
	{
		pid: 34,
		lpSymbol: 'KETH-BNB LP',
		imageName: 'keth-bnb',
		lpAddresses: {
			97: '',
			56: '0x1a7b2c2cdb81f7053bd43e06a2a362be4bc14105',
		},
		token: tokens.keth,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 35,
		lpSymbol: 'KKCS-BNB LP',
		imageName: 'kkcs-bnb',
		lpAddresses: {
			97: '',
			56: '0x90114a70b0f1ffc84c8a9e60e8033f66834900b2',
		},
		token: tokens.kkcs,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 36,
		lpSymbol: 'WAFI-BNB LP',
		imageName: 'wafi-bnb',
		lpAddresses: {
			97: '',
			56: '0x43e8e6723a53bfe15455aab5f79f1249b242febb',
		},
		token: tokens.wafi,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 37,
		lpSymbol: 'WSAFEPAWS-BNB LP',
		imageName: 'wsafepaws-bnb',
		lpAddresses: {
			97: '',
			56: '0x57eb906f6732b5e42b777f9c73d9744867318a6a',
		},
		token: tokens.wsafepaws,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 38,
		lpSymbol: 'ALE-BNB LP',
		imageName: 'ale-bnb',
		lpAddresses: {
			97: '',
			56: '0x1d6cdcfe824ca2b196d700c5c01e5105152fcee2',
		},
		token: tokens.ale,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 39,
		lpSymbol: 'SZO-BNB LP',
		imageName: 'szo-bnb',
		lpAddresses: {
			97: '',
			56: '0x8b35c5f58009fcd6538f87d42fac540a7d4f7163',
		},
		token: tokens.szo,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 40,
		lpSymbol: 'FINIX-BNB LP',
		imageName: 'finix-bnb',
		lpAddresses: {
			97: '',
			56: '0x4d95fc88a32c8eab7f1b56c49f4668ff3b9f5583',
		},
		token: tokens.finix,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 41,
		lpSymbol: 'LEAF-BNB LP',
		imageName: 'leaf-bnb',
		lpAddresses: {
			97: '',
			56: '0x8ed24745ed0858d6be17d50c3dc58bd5cf3e4e8f',
		},
		token: tokens.leaf,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 43,
		lpSymbol: 'CEA-BNB LP',
		imageName: 'cea-bnb',
		lpAddresses: {
			97: '',
			56: '0xb59be39c8e8c10222f14cd6b69150c1241977d06',
		},
		token: tokens.cea,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 46,
		lpSymbol: 'CAKE-BNB LP',
		imageName: 'cake-bnb',
		lpAddresses: {
			97: '',
			56: '0xf90e7a019b73b125e6937737097d52549900b460',
		},
		token: tokens.cake,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 49,
		lpSymbol: 'TENFI-BNB LP',
		imageName: 'tenfi-bnb',
		lpAddresses: {
			97: '',
			56: '0x87b874c8cd424ce13c65a95b4bd2ae488f166856',
		},
		token: tokens.tenfi,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 50,
		lpSymbol: 'BSW-BNB LP',
		imageName: 'bsw-bnb',
		lpAddresses: {
			97: '',
			56: '0xce0e9bded194b755aa7815faecfee851fc1f9fb0',
		},
		token: tokens.bsw,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 51,
		lpSymbol: 'BANANA-BNB LP',
		imageName: 'banana-bnb',
		lpAddresses: {
			97: '',
			56: '0x2b129AA5744c697c0A580296EC81c1Bd0A717E67',
		},
		token: tokens.banana,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 52,
		lpSymbol: 'DOPX-BNB LP',
		imageName: 'dopx-bnb',
		lpAddresses: {
			97: '',
			56: '0x21670ac7CCfEd702ff02763B20766DFbc869da32',
		},
		token: tokens.dopx,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 53,
		lpSymbol: 'LOOM-BNB LP',
		imageName: 'loom-bnb',
		lpAddresses: {
			97: '',
			56: '0x35ddf5ae5c0fcc69a5ac0536b386962de706a936',
		},
		token: tokens.loom,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 54,
		lpSymbol: 'UNI-BNB LP',
		imageName: 'uni-bnb',
		lpAddresses: {
			97: '',
			56: '0x949a53a16157e9fe8340237a4da9943d28a5f616',
		},
		token: tokens.uni,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 55,
		lpSymbol: 'SUSHI-BNB LP',
		imageName: 'sushi-bnb',
		lpAddresses: {
			97: '',
			56: '0x5a39F24FA18E89Ad1FD16D1c90228cc11BbCD636',
		},
		token: tokens.sushi,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 56,
		lpSymbol: 'ALPACA-BNB LP',
		imageName: 'alpaca-bnb',
		lpAddresses: {
			97: '',
			56: '0xab586E8664d1864F513edcE638a5754273Ac1e97',
		},
		token: tokens.alpaca,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 57,
		lpSymbol: 'WEX-BNB LP',
		imageName: 'wex-bnb',
		lpAddresses: {
			97: '',
			56: '0x18234010A241A016b5165B5569ea4A2561D5065e',
		},
		token: tokens.wex,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 58,
		lpSymbol: 'EGG-BNB LP',
		imageName: 'egg-bnb',
		lpAddresses: {
			97: '',
			56: '0xa11f9f0E972edf589534E8174b5de75E2eE826fF',
		},
		token: tokens.egg,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 59,
		lpSymbol: 'PANTHER-BNB LP',
		imageName: 'panther-bnb',
		lpAddresses: {
			97: '',
			56: '0xd59E5280171f0A3401fd94F7Ec9EabfFDB941578',
		},
		token: tokens.panther,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 60,
		lpSymbol: 'TUK-BNB LP',
		imageName: 'tuk-bnb',
		lpAddresses: {
			97: '',
			56: '0x3e35b9d761db8b8fca037d6eb84389b81ae8366f',
		},
		token: tokens.tuk,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 61,
		lpSymbol: 'KEBAB-BNB LP',
		imageName: 'kebab-bnb',
		lpAddresses: {
			97: '',
			56: '0xc9388efa1f24e2f16e8717841b1b15043384da43',
		},
		token: tokens.kebab,
		quoteToken: tokens.wbnb,
	},
	{
		pid: 62,
		lpSymbol: 'PACOCA-BNB LP',
		imageName: 'pacoca-bnb',
		lpAddresses: {
			97: '',
			56: '0x09534834a2abec732f56327afd9d51b2600e3640',
		},
		token: tokens.pacoca,
		quoteToken: tokens.wbnb,
	},

	// Safe coins
	{
		pid: 21,
		lpSymbol: 'wMMP',
		imageName: 'wmmp-coupon',
		lpAddresses: {
			97: '0xf37f180fBbA29681AcDcD7dca13F37c64eAB3272',
			56: '0x422d0a431d8fb752e3697e90ba04b3324ea0cb4a',
		},
		token: tokens.wmmp,
		quoteToken: tokens.wmmp,
		isSinglePool: true,
	},
	{
		pid: 17,
		lpSymbol: 'wSAFEMOON',
		imageName: 'wsafemoon-coupon',
		lpAddresses: {
			97: '0xf37f180fBbA29681AcDcD7dca13F37c64eAB3272',
			56: '0xa3863434a1Fc699185b3E6809a933056D1178366',
		},
		token: tokens.wsafemoon,
		quoteToken: tokens.wsafemoon,
		isSinglePool: true,
		decimals: 9,
	},
	{
		pid: 18,
		lpSymbol: 'wSAFEMARS',
		imageName: 'wsafemars-coupon',
		lpAddresses: {
			97: '0xf37f180fBbA29681AcDcD7dca13F37c64eAB3272',
			56: '0x40733aBc9Acb7d48Caa632ee83E4e7B3d0008d9D',
		},
		token: tokens.wsafemars,
		quoteToken: tokens.wsafemars,
		isSinglePool: true,
		decimals: 9,
	},
	{
		pid: 19,
		lpSymbol: 'rSAFEMOON',
		imageName: 'rsafemoon-coupon',
		lpAddresses: {
			97: '0xf37f180fBbA29681AcDcD7dca13F37c64eAB3272',
			56: '0xeA760c8de4ce92e34cc3BD8b4ad9a85C13a91e6C',
		},
		token: tokens.rsafemoon,
		quoteToken: tokens.rsafemoon,
		isSinglePool: true,
		isWithdrawFee100: true,
		decimals: 9,
	},
	{
		pid: 20,
		lpSymbol: 'rSAFEMARS',
		imageName: 'rsafemars-coupon',
		lpAddresses: {
			97: '0xf37f180fBbA29681AcDcD7dca13F37c64eAB3272',
			56: '0xA651fdAa684B704C8e64eBbb71413245C01f52a1',
		},
		token: tokens.rsafemars,
		quoteToken: tokens.rsafemars,
		isSinglePool: true,
		isWithdrawFee100: true,
		decimals: 9,
	},
	{
		pid: 25,
		lpSymbol: 'rMMP',
		imageName: 'rmmp-coupon',
		lpAddresses: {
			97: '0xf37f180fBbA29681AcDcD7dca13F37c64eAB3272',
			56: '0x3e45fd22cc219f640b3a608e274f5e0a5a6256a5',
		},
		token: tokens.rmmp,
		quoteToken: tokens.rmmp,
		isSinglePool: true,
		isWithdrawFee100: true,
	},
]

export default farms
