import { darkColors, lightColors } from '../../theme/colors'
import { NavTheme } from './types'

export const light: NavTheme = {
	background: lightColors.backgroundAlt,
	hover: '#EEEAF4',
}

export const dark: NavTheme = {
	background: darkColors.backgroundAlt,
	hover: '#473d5d',
}
