import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import {
	fetchFarmUserDataAsync,
	fetchFarmUserDataAsyncV2,
	updateUserBalance,
	updateUserPendingReward,
} from 'state/actions'
import { soushHarvest, soushHarvestBnb, harvest } from 'utils/callHelpers'
import { useCafeteriaV2, useMasterchef, useSousChef } from './useContract'

export const useHarvest = (farmPid: number) => {
	const dispatch = useAppDispatch()
	const { account } = useWeb3React()
	const masterChefContract = useMasterchef()

	const handleHarvest = useCallback(async () => {
		const txHash = await harvest(masterChefContract, farmPid, account)
		dispatch(fetchFarmUserDataAsync(account))
		return txHash
	}, [account, dispatch, farmPid, masterChefContract])

	return { onReward: handleHarvest }
}

export const useHarvestV2 = (farmPid: number) => {
	const dispatch = useAppDispatch()
	const { account } = useWeb3React()
	const cafeteriaV2Contract = useCafeteriaV2()

	const handleHarvest = useCallback(async () => {
		const txHash = await harvest(cafeteriaV2Contract, farmPid, account)
		dispatch(fetchFarmUserDataAsyncV2(account))
		return txHash
	}, [account, dispatch, farmPid, cafeteriaV2Contract])

	return { onReward: handleHarvest }
}

export const useAllHarvest = (farmPids: number[]) => {
	const { account } = useWeb3React()
	const masterChefContract = useMasterchef()

	const handleHarvest = useCallback(async () => {
		const harvestPromises = farmPids.reduce((accum, pid) => {
			return [...accum, harvest(masterChefContract, pid, account)]
		}, [])

		return Promise.all(harvestPromises)
	}, [account, farmPids, masterChefContract])

	return { onReward: handleHarvest }
}

export const useAllHarvestV2 = (farmPids: number[]) => {
	const { account } = useWeb3React()
	const masterChefContract = useCafeteriaV2()

	const handleHarvest = useCallback(async () => {
		const harvestPromises = farmPids.reduce((accum, pid) => {
			return [...accum, harvest(masterChefContract, pid, account)]
		}, [])

		return Promise.all(harvestPromises)
	}, [account, farmPids, masterChefContract])

	return { onReward: handleHarvest }
}

export const useSousHarvest = (sousId, isUsingBnb = false) => {
	const dispatch = useAppDispatch()
	const { account } = useWeb3React()
	const sousChefContract = useSousChef(sousId)
	const masterChefContract = useMasterchef()

	const handleHarvest = useCallback(async () => {
		if (sousId === 0) {
			await harvest(masterChefContract, 0, account)
		} else if (isUsingBnb) {
			await soushHarvestBnb(sousChefContract, account)
		} else {
			await soushHarvest(sousChefContract, account)
		}
		dispatch(updateUserPendingReward(sousId, account))
		dispatch(updateUserBalance(sousId, account))
	}, [account, dispatch, isUsingBnb, masterChefContract, sousChefContract, sousId])

	return { onReward: handleHarvest }
}
