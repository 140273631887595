import React from 'react'
import Button from '../../components/Button/Button'
import Text from '../../components/Text/Text'
import { connectorLocalStorageKey } from './config'
import { Login, Config } from './types'

interface Props {
	walletConfig: Config
	login: Login
	onDismiss: () => void
	mb: string
}

const WalletCard: React.FC<Props> = ({ login, walletConfig, onDismiss, mb }) => {
	const { title, icon: Icon } = walletConfig
	return (
		<div
			className="u-transition-1 _bgcl-primary-100 _bgcl-primary-200-hover _dp-f _alit-ct _jtfct-spbtw _pdh-16px _pdv-12px _mgbt-8px _bdrd-8px _cs-pt"
			onClick={() => {
				login(walletConfig.connectorId)
				window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId)
				onDismiss()
			}}
			style={{ justifyContent: 'space-between' }}
			id={`wallet-connect-${title.toLocaleLowerCase()}`}
		>
			<div className="_fw-600 _cl-primary-700">{title}</div>
			<div className="u-raised-1 _bdrd-max">
				<Icon width="32px" />
			</div>
		</div>
	)
}

export default WalletCard
