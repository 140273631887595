import addresses from 'config/constants/contracts'
import tokens from 'config/constants/tokens'
import { Address } from 'config/constants/types'

export const getAddress = (address: Address): string => {
	const mainNetChainId = 56
	const chainId = process.env.REACT_APP_CHAIN_ID
	return address[chainId] ? address[chainId] : address[mainNetChainId]
}

export const getCouponAddress = () => {
	return getAddress(tokens.coupon.address)
}
export const getCakeAddress = () => {
	return getAddress(tokens.coupon.address)
}
export const getMasterChefAddress = () => {
	return getAddress(addresses.masterChef)
}
export const getCafeteriaV2Address = () => {
	return getAddress(addresses.cafeteriaV2)
}
export const getMulticallAddress = () => {
	return getAddress(addresses.multiCall)
}
export const getWbnbAddress = () => {
	return getAddress(tokens.wbnb.address)
}
