import React, { useEffect, lazy } from 'react'
import { Router, Redirect, Route, Switch } from 'react-router-dom'
import { ResetCSS } from './uikit-foodcourt'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import { useFetchPublicData, useFetchPriceList } from 'state/hooks'
import GlobalStyle from './style/Global'
import Menu from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import ToastListener from './components/ToastListener'
import PageLoader from './components/PageLoader'
import EasterEgg from './components/EasterEgg'
import Pools from './views/Pools'
import PoolsV2 from './views/PoolsV2'
import history from './routerHistory'
import Embed from './Embed'
import { useWeb3React } from '@web3-react/core'

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const FarmsV2 = lazy(() => import('./views/FarmsV2'))
const NotFound = lazy(() => import('./views/NotFound'))

// This config is required for number formating
BigNumber.config({
	EXPONENTIAL_AT: 1000,
	DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
	const { account } = useWeb3React()

	// Monkey patch warn() because of web3 flood
	// To be removed when web3 1.3.5 is released
	useEffect(() => {
		console.warn = () => null
	}, [])

	useEagerConnect()
	useFetchPublicData()
	useFetchPriceList()

	return (
		<Router history={history}>
			<ResetCSS />
			<GlobalStyle />
			<Menu>
				<SuspenseWithChunkError fallback={<PageLoader />}>
					<>
						<Switch>
							<Route path="/" exact>
								<Home />
							</Route>
							<Route path="/farms">
								<Farms />
							</Route>
							<Route path="/farmsV2">
								<FarmsV2 />
							</Route>
							<Route path="/pools">
								<Pools />
							</Route>
							<Route path="/poolsV2">
								<PoolsV2 />
							</Route>
							<Route path="/portfolio">
								<Embed src={account ? `https://loremboard.finance/embed/partner/history/foodcourt/${account}?theme=light` : "https://loremboard.finance"} />
							</Route>
							{/* Redirect */}
							<Route path="/staking">
								<Redirect to="/pools" />
							</Route>
							<Route path="/syrup">
								<Redirect to="/pools" />
							</Route>
							{/* 404 */}
							<Route component={NotFound} />
						</Switch>
						{/* inspex */}
						<div className="_h-64px" />
						<a href="https://app.inspex.co/library/foodcourt#?scope=foodcourt-amm-farm-wrappedtokens" target="_blank" className="_pst-f _bt-12px _r-12px" rel="noreferrer">
							<div className="_fw-600 _pdv-8px _pdh-32px _bdrd-max _dp-f _alit-ct">
								<div className="_mgr-12px-md _mgr-12px _dp-f _alit-ct">
									<div className="_w-12px _h-12px _bgcl-positive-500 _bdrd-max _mgr-4px" style={{ boxShadow: '1px 1px 0px white, 2px 2px 0px #F7D058, 3px 3px 0px #31C155' }} />
									<div className="_cl-accent-500 _fs-400-md _fs-300" style={{ color: 'var(--color-accent-500)', textShadow: '1px 1px 0px white, 2px 2px 0px #F7D058, 3px 3px 0px #31C155' }}>audited by</div>
								</div>
								<div className="_pd-12px _bdrd-8px" style={{ backgroundColor: '#051026' }}>
									<img src="/images/inspex.png" className="_h-24px" alt="inspex.png" />
								</div>
							</div>
						</a>
					</>
				</SuspenseWithChunkError>
			</Menu>
			<EasterEgg iterations={2} />
			<ToastListener />
		</Router>
	)
}

export default React.memo(App)
