import React, { useEffect } from 'react'

const CouponPrice = ({
	price = 1.0,
	theme = 'white',
}: {
	price?: number
	theme?: 'white' | 'primary'
}): JSX.Element => {
	const style =
		theme === 'white'
			? { backgroundColor: 'white', boxShadow: '0px 2px 6px rgba(0,0,0,0.1)' }
			: { backgroundColor: 'var(--color-primary-100)' }

	return (
		<>
			<div id="chain-select-button" onClick={() => {
				window.open("https://dex.guru/token/0x084bb94e93891d74579b54ab63ed24c4ef9cd5ef-bsc");
			}}>
				<div className="_dp-f _alit-ct _pdr-12px _bdrd-max _cs-pt" style={style}>
					<img
						width="30"
						height="30"
						src="/images/coupon.svg"
						alt="BSC"
						className="_dp-b"
						style={{
							transform: 'scale(1.2)',
						}}
					/>
					<div className="_dp-f _alit-ct _mgl-12px">
						<div className="_cl-primary-800 _fw-800">{`$${price.toFixed(3)}`}</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default CouponPrice
