import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Route, useLocation, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { Heading, Flex, Image, Text, RowType } from '../../uikit-foodcourt'
import orderBy from 'lodash/orderBy'
import partition from 'lodash/partition'
import { useTranslation } from 'contexts/Localization'
import usePersistState from 'hooks/usePersistState'
import {
	usePoolsV2,
	useFetchPublicPoolsDataV2,
	useFarmsV2,
	usePriceCouponBusdV2,
	useGetApiPrices,
} from 'state/hooks'
import { latinise } from 'utils/latinise'
import FlexLayout from 'components/layout/Flex'
import Page from 'components/layout/Page'
import PageHeader from 'components/PageHeader'
import SearchInput from 'components/SearchInput'
import Select, { OptionProps } from 'components/Select/Select'
import { Farm, Pool } from 'state/types'
import PoolCard from './components/PoolCard'
import PoolTabButtons from './components/PoolTabButtons'
import PoolsTable from './components/PoolsTable/PoolsTable'
import { ViewMode } from './components/ToggleView/ToggleView'
import { getAprData } from './helpers'
import { getBalanceNumber } from 'utils/formatBalance'
import FarmCard, { FarmWithStakedValue } from 'views/FarmsV2/components/FarmCard/FarmCard'
import { RowProps } from 'views/FarmsV2/components/FarmTable/Row'
import { DesktopColumnSchema } from 'views/FarmsV2/components/types'
import FarmTable from 'views/FarmsV2/components/FarmTable/FarmTable'
import { getFarmAprV2 } from 'utils/apr'
import { getAddress } from 'utils/addressHelpers'
import isArchivedPid from 'utils/farmHelpers'
import useRefresh from 'hooks/useRefresh'
import { fetchFarmUserDataAsyncV2 } from 'state/actions'
import { useAppDispatch } from 'state'

const CardLayout = styled(FlexLayout)`
	justify-content: center;
`

const PoolControls = styled(Flex)`
	flex-direction: column;
	margin-bottom: 24px;
	margin-bottom: 32px;
	background: white;
	border-radius: 24px;
	box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
	padding: 16px;

	${({ theme }) => theme.mediaQueries.md} {
		flex-direction: row;
	}
`

const SearchSortContainer = styled(Flex)`
	gap: 10px;
	justify-content: space-between;
`

const ControlStretch = styled(Flex)`
	> div {
		flex: 1;
	}
`

const TypeTitleText = styled.div`
	font-weight: bold;
	font-size: larger;
	color: hsl(41, 99%, 39%);
`

const NUMBER_OF_POOLS_VISIBLE = 128

const Pools: React.FC = () => {
	const { path } = useRouteMatch()
	const location = useLocation()
	const { pathname } = location
	const { t } = useTranslation()
	const { account } = useWeb3React()
	const { pools: poolsWithoutAutoVault, userDataLoaded: poolUserDataLoaded } = usePoolsV2(account)
	const [stakedOnly, setStakedOnly] = usePersistState(false, 'pancake_pool_staked')
	const [numberOfPoolsVisible, setNumberOfPoolsVisible] = useState(NUMBER_OF_POOLS_VISIBLE)
	const [observerIsSet, setObserverIsSet] = useState(false)
	const loadMoreRef = useRef<HTMLDivElement>(null)
	const [viewMode, setViewMode] = usePersistState(ViewMode.TABLE, 'pancake_farm_view')
	const [searchQuery, setSearchQuery] = useState('')
	const [sortOption, setSortOption] = useState('hot')
	const { data: farmsLP, userDataLoaded: farmUserDataLoaded } = useFarmsV2()
	const couponPrice = usePriceCouponBusdV2()
	const userDataLoaded = poolUserDataLoaded && farmUserDataLoaded
	const prices = useGetApiPrices()

	const pools = useMemo(() => {
		const cakePool = poolsWithoutAutoVault.find((pool) => pool.sousId === 0)
		const cakeAutoVault = { ...cakePool, isAutoVault: true }
		return [cakeAutoVault, ...poolsWithoutAutoVault]
	}, [poolsWithoutAutoVault])

	// TODO aren't arrays in dep array checked just by reference, i.e. it will rerender every time reference changes?
	const [finishedPools, openPools] = useMemo(() => partition(pools, (pool) => pool.isFinished), [
		pools,
	])
	const stakedOnlyFinishedPools = useMemo(
		() =>
			finishedPools.filter((pool) => {
				return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
			}),
		[finishedPools],
	)
	const stakedOnlyOpenPools = useMemo(
		() =>
			openPools.filter((pool) => {
				return pool.userData && new BigNumber(pool.userData.stakedBalance).isGreaterThan(0)
			}),
		[openPools],
	)
	const hasStakeInFinishedPools = stakedOnlyFinishedPools.length > 0

	useFetchPublicPoolsDataV2()

	const dispatch = useAppDispatch()
	const { fastRefresh } = useRefresh()
	useEffect(() => {
		if (account) {
			dispatch(fetchFarmUserDataAsyncV2(account))
		}
	}, [account, dispatch, fastRefresh])

	useEffect(() => {
		const showMorePools = (entries) => {
			const [entry] = entries
			if (entry.isIntersecting) {
				setNumberOfPoolsVisible(
					(poolsCurrentlyVisible) => poolsCurrentlyVisible + NUMBER_OF_POOLS_VISIBLE,
				)
			}
		}

		if (!observerIsSet) {
			const loadMoreObserver = new IntersectionObserver(showMorePools, {
				rootMargin: '0px',
				threshold: 1,
			})
			loadMoreObserver.observe(loadMoreRef.current)
			setObserverIsSet(true)
		}
	}, [observerIsSet])

	const showFinishedPools = location.pathname.includes('history')

	const handleChangeSearchQuery = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearchQuery(event.target.value)
	}

	const handleSortOptionChange = (option: OptionProps): void => {
		setSortOption(option.value)
	}

	const sortPools = (poolsToSort: Pool[]) => {
		switch (sortOption) {
			case 'apr':
				// Ternary is needed to prevent pools without APR (like MIX) getting top spot
				return orderBy(
					poolsToSort,
					(pool: Pool) => (pool.apr ? getAprData(pool, 0).apr : 0),
					'desc',
				)
			case 'earned':
				return orderBy(
					poolsToSort,
					(pool: Pool) => {
						if (!pool.userData || !pool.earningTokenPrice) {
							return 0
						}
						return pool.userData.pendingReward.times(pool.earningTokenPrice).toNumber()
					},
					'desc',
				)
			case 'totalStaked':
				return orderBy(
					poolsToSort,
					(pool: Pool) => pool.totalStaked.toNumber(),
					'desc',
				)
			default:
				return poolsToSort
		}
	}

	const poolsToShow = () => {
		let chosenPools = []
		if (showFinishedPools) {
			chosenPools = stakedOnly ? stakedOnlyFinishedPools : finishedPools
		} else {
			chosenPools = stakedOnly ? stakedOnlyOpenPools : openPools
		}

		if (searchQuery) {
			const lowercaseQuery = latinise(searchQuery.toLowerCase())
			chosenPools = chosenPools.filter((pool) =>
				latinise(pool.earningToken.symbol.toLowerCase()).includes(lowercaseQuery),
			)
		}

		return sortPools(chosenPools)
			.slice(0, numberOfPoolsVisible)
			.filter((c) => !c.isAutoVault && c.sousId > 0)
	}

	const cardLayout = (
		<CardLayout>
			{poolsToShow().map((pool) =>
				<PoolCard key={pool.sousId} pool={pool} account={account} />
			)}
		</CardLayout>
	)

	const tableLayout = (
		<PoolsTable pools={poolsToShow()} account={account} userDataLoaded={userDataLoaded} />
	)

	const userDataReady = !account || (!!account && userDataLoaded)

	// Farm system but single asset instead
	const isArchived = pathname.includes('archived')
	const isInactive = pathname.includes('history')
	const isActive = !isInactive && !isArchived

	const farmsList = useCallback(
		(farmsToDisplay: Farm[]): FarmWithStakedValue[] => {
			let farmsToDisplayWithAPR: FarmWithStakedValue[] = farmsToDisplay.map((farm) => {
				if (!farm.lpTotalInQuoteToken || !prices) {
					return farm
				}

				// API must response WBNB price, BUSD price, DOLLY price, ...
				const quoteTokenPriceUsd = prices[getAddress(farm.quoteToken.address).toLowerCase()]
				const totalLiquidity = new BigNumber(farm.lpTotalInQuoteToken).times(quoteTokenPriceUsd)
				const apr = isActive
					? getFarmAprV2(new BigNumber(farm.poolWeight), couponPrice, totalLiquidity)
					: 0

				// console.log("Total liquidity", getAddress(farm.quoteToken.address).toLowerCase(), farm.lpTotalInQuoteToken.toString());

				return { ...farm, apr, liquidity: totalLiquidity }
			})

			if (searchQuery) {
				const lowercaseQuery = latinise(searchQuery.toLowerCase())
				farmsToDisplayWithAPR = farmsToDisplayWithAPR.filter((farm: FarmWithStakedValue) => {
					return latinise(farm.lpSymbol.toLowerCase()).includes(lowercaseQuery)
				})
			}

			return farmsToDisplayWithAPR
		},
		[couponPrice, prices, searchQuery, isActive],
	)

	const activeFarms = farmsLP.filter(
		(farm) => farm.isSinglePool && farm.multiplier !== '0X' && !isArchivedPid(farm.pid),
	)
	const inactiveFarms = farmsLP.filter(
		(farm) => farm.isSinglePool && farm.multiplier === '0X' && !isArchivedPid(farm.pid),
	)
	const archivedFarms = farmsLP.filter((farm) => isArchivedPid(farm.pid))

	const stakedOnlyFarms = activeFarms.filter(
		(farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
	)

	const stakedInactiveFarms = inactiveFarms.filter(
		(farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
	)

	const stakedArchivedFarms = archivedFarms.filter(
		(farm) => farm.userData && new BigNumber(farm.userData.stakedBalance).isGreaterThan(0),
	)

	const farmsStakedMemoized = useMemo(() => {
		let farmsStaked = []

		const sortFarms = (farms: FarmWithStakedValue[]): FarmWithStakedValue[] => {
			switch (sortOption) {
				case 'apr':
					return orderBy(farms, (farm: FarmWithStakedValue) => farm.apr, 'desc')
				case 'multiplier':
					return orderBy(
						farms,
						(farm: FarmWithStakedValue) =>
							farm.multiplier ? Number(farm.multiplier.slice(0, -1)) : 0,
						'desc',
					)
				case 'earned':
					return orderBy(
						farms,
						(farm: FarmWithStakedValue) => (farm.userData ? Number(farm.userData.earnings) : 0),
						'desc',
					)
				case 'liquidity':
					return orderBy(farms, (farm: FarmWithStakedValue) => Number(farm.liquidity), 'desc')
				default:
					return farms
			}
		}

		if (isActive) {
			farmsStaked = stakedOnly ? farmsList(stakedOnlyFarms) : farmsList(activeFarms)
		}
		if (isInactive) {
			farmsStaked = stakedOnly ? farmsList(stakedInactiveFarms) : farmsList(inactiveFarms)
		}
		if (isArchived) {
			farmsStaked = stakedOnly ? farmsList(stakedArchivedFarms) : farmsList(archivedFarms)
		}

		return sortFarms(farmsStaked) // .slice(0, numberOfFarmsVisible)
	}, [
		sortOption,
		activeFarms,
		farmsList,
		inactiveFarms,
		archivedFarms,
		isActive,
		isInactive,
		isArchived,
		stakedArchivedFarms,
		stakedInactiveFarms,
		stakedOnly,
		stakedOnlyFarms,
		// numberOfFarmsVisible,
	])

	const rowData = farmsStakedMemoized.map((farm) => {
		const { token, quoteToken } = farm
		const tokenAddress = token.address
		const quoteTokenAddress = quoteToken.address
		const lpLabel =
			farm.lpSymbol && farm.lpSymbol.split(' ')[0].toUpperCase().replace('PANCAKE', '')

		const row: RowProps = {
			apr: {
				value: farm.apr && farm.apr.toLocaleString('en-US', { maximumFractionDigits: 2 }),
				multiplier: farm.multiplier,
				lpLabel,
				tokenAddress,
				quoteTokenAddress,
				couponPrice,
				originalValue: farm.apr,
				lpValueInUsd: farm.lpValueInUsd,
			},
			farm: {
				image: farm.imageName,
				label: lpLabel,
				pid: farm.pid,
				single: farm.isSinglePool
			},
			earned: {
				earnings: getBalanceNumber(new BigNumber(farm.userData.earnings)),
				pid: farm.pid,
			},
			liquidity: {
				liquidity: farm.liquidity,
			},
			multiplier: {
				multiplier: farm.multiplier,
			},
			details: farm,
		}

		return row
	})

	const renderFarmContent = (): JSX.Element => {
		if (viewMode === ViewMode.TABLE && rowData.length) {
			const columnSchema = DesktopColumnSchema

			const columns = columnSchema.map((column) => ({
				id: column.id,
				name: column.name,
				label: column.label,
				sort: (a: RowType<RowProps>, b: RowType<RowProps>) => {
					switch (column.name) {
						case 'farm':
							return b.id - a.id
						case 'apr':
							if (a.original.apr.value && b.original.apr.value) {
								return Number(a.original.apr.value) - Number(b.original.apr.value)
							}

							return 0
						case 'earned':
							return a.original.earned.earnings - b.original.earned.earnings
						default:
							return 1
					}
				},
				sortable: column.sortable,
			}))

			return <FarmTable data={rowData} columns={columns} userDataReady={userDataReady} />
		}

		return (
			<div>
				<FlexLayout>
					<Route exact path={`${path}`}>
						{farmsStakedMemoized.map((farm) => (
							<FarmCard
								key={farm.pid}
								farm={farm}
								couponPrice={couponPrice}
								account={account}
								removed={false}
								single
								withdraw100fee={farm.isWithdrawFee100}
							/>
						))}
					</Route>
					<Route exact path={`${path}/history`}>
						{farmsStakedMemoized.map((farm) => (
							<FarmCard
								key={farm.pid}
								farm={farm}
								couponPrice={couponPrice}
								account={account}
								removed
								single
								withdraw100fee={farm.isWithdrawFee100}
							/>
						))}
					</Route>
					<Route exact path={`${path}/archived`}>
						{farmsStakedMemoized.map((farm) => (
							<FarmCard
								key={farm.pid}
								farm={farm}
								couponPrice={couponPrice}
								account={account}
								removed
								single
								withdraw100fee={farm.isWithdrawFee100}
							/>
						))}
					</Route>
				</FlexLayout>
			</div>
		)
	}

	return (
		<>
			<PageHeader>
				<Flex justifyContent="space-between" flexDirection={['column', null, 'row']}>
					<Flex flexDirection="column" mr={['8px', 0]}>
						<h2 className="_fs-800 _cl-primary-700">Snack bar</h2>
						<Heading size="lg" color="text">
							Simply stake tokens to earn.
						</Heading>
						<Heading scale="md" color="text">
							{t('Just stake some tokens to earn.')}
						</Heading>
						<Heading scale="md" color="text">
							{t('High APR, low risk.')}
						</Heading>
					</Flex>
					{/* <Flex flex="1" height="fit-content" justifyContent="center" alignItems="center" mt={['24px', null, '0']}>
            <HelpButton />
            <BountyCard />
          </Flex> */}
				</Flex>
			</PageHeader>
			<Page>
				<div>
					<PoolControls justifyContent="space-between">
						<PoolTabButtons
							stakedOnly={stakedOnly}
							setStakedOnly={setStakedOnly}
							hasStakeInFinishedPools={hasStakeInFinishedPools}
							viewMode={viewMode}
							setViewMode={setViewMode}
						/>
						<SearchSortContainer>
							<Flex flexDirection="column" width="50%">
								<Text fontSize="12px" bold color="textSubtle" textTransform="uppercase">
									{t('Sort by')}
								</Text>
								<ControlStretch>
									<Select
										options={[
											{
												label: t('Hot'),
												value: 'hot',
											},
											{
												label: t('APR'),
												value: 'apr',
											},
											{
												label: t('Earned'),
												value: 'earned',
											},
											{
												label: t('Total staked'),
												value: 'totalStaked',
											},
										]}
										onChange={handleSortOptionChange}
									/>
								</ControlStretch>
							</Flex>
							<Flex flexDirection="column" width="50%">
								<Text fontSize="12px" bold color="textSubtle" textTransform="uppercase">
									{t('Search')}
								</Text>
								<ControlStretch>
									<SearchInput onChange={handleChangeSearchQuery} placeholder="Search Pools" />
								</ControlStretch>
							</Flex>
						</SearchSortContainer>
					</PoolControls>
					{showFinishedPools && (
						<Text fontSize="20px" color="failure" pb="32px">
							{t('These pools are no longer distributing rewards. Please unstake your tokens.')}
						</Text>
					)}

					<TypeTitleText>Earn COUPON</TypeTitleText>
					{renderFarmContent()}

					<div style={{ marginTop: 36, marginBottom: 36 }}>
						<TypeTitleText>Earn Tokens</TypeTitleText>
						<div style={{ marginTop: 16 }}>
							{viewMode === ViewMode.CARD ? cardLayout : tableLayout}
						</div>
					</div>

					<div ref={loadMoreRef} />
					<Image
						mx="auto"
						mt="12px"
						src="/images/food6.svg"
						alt="Foodcourt illustration"
						width={150}
						height={150}
					/>
				</div>
			</Page>
		</>
	)
}

export default Pools
